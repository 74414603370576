import styled from 'styled-components';

export const StyledP = styled('h5')`
    font-size: 16px;
    padding: 30px 0;
    text-align: center;
`;

export const StyledH5 = styled('h5')`
    font-size: 18px;
    color: #707070;
    font-weight: bold;
    margin: 0;
`;

export const StyledStatusBadge = styled('div')<{ color?: string }>`
    background-color: ${(props) => props.color};
    color: #fff;
    padding: 6px 8px 6px 8px;
    font-size: 12px;
    vertical-align: middle;
    margin-left: 10px;
    border-radius: 4px;
    text-align: center;
    font-weight: 700;
`;
