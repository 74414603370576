import pages from '../constants/pages';
import Page from '../shared/interfaces/page.interface';
import userHasRoles from '../utils/userHasRoles';
import { useAuth } from './use-auth';

export default function useAllowedPages(): Page[] {
    const { user } = useAuth();

    const allowedPages = pages.filter(
        ({ roles }) => !roles.length || userHasRoles(user, roles),
    );

    return allowedPages;
}
