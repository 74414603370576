import { NavLink } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledH6 = styled.h6`
    font-size: 10px !important;
    color: white;
    padding-left: 0px;
`;

export const StyledLinkSeller = styled(NavLink)`
    font-size: 13px !important;
    font-weight: bolder !important;
    color: white !important;
    padding-left: 0px;

    :hover {
        text-decoration: underline;
        color: none !important;
    }
`;

export const StyledDiv = styled.div`
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    p {
        color: #545454;
    }

    p:first-child {
        font-size: 16px !important;
        font-weight: bold;
        margin: 0;
    }

    p:nth-child(2) {
        font-size: 14px !important;
        margin: 5px 0 0 0;
    }

    :hover {
        background-color: #f0f0f0;
    }

    :hover p:first-child {
        color: #7000d1;
    }
`;
