import { AUTH_API_URL } from '../../config/urls';
import { BatchUpdateTypesEnum } from '../../constants/batchUpdateTypes';
import ConciseProductionInvoice from '../../shared/interfaces/conciseProductionInvoice.interface';
import useRequest from './request';

export default function usePortalHttpService() {
    const { get, patch } = useRequest({ baseURL: AUTH_API_URL });

    const URI = '/logistics';

    const indexModules = async () => {
        return get(`/kits/modules/filters`);
    };

    const indexModulesFilterOptions = async () => {
        return get(`/kits/modulesOptions`);
    };

    const indexProduction = async (data: any) => {
        return get(`${URI}`, data);
    };

    const indexProductionNoParams = async () => {
        return get(`${URI}/listInvoices`);
    };

    const indexStates = async () => {
        return get(`${URI}/states`);
    };

    const indexCities = async (data: { state: string }) => {
        return get(`${URI}/cities`, data);
    };

    const patchInvoice = async (data: any) => {
        return patch(`${URI}`, data);
    };

    const getInvoicePdf = async (id: Number) => {
        return get(`/invoices/${id}/print`);
    };

    const getNfeSimpleShipmentPdf = async (id: Number) => {
        return get(`/invoices/${id}/nfe-simple-shipment`);
    };

    const getNfeFutureSalePdf = async (id: Number) => {
        return get(`/invoices/${id}/nfe-future-sale`);
    };

    const getNfeTriangulation = async (id: Number) => {
        return get(`/invoices/${id}/triangulation/nf`);
    };

    const getConciseProductionInvoices = async (params: {
        tenantId: string;
        searchTerm: string;
    }) => {
        return get<ConciseProductionInvoice[]>(
            `${URI}/invoices/concise`,
            params,
        );
    };

    const batchUpdateInvoices = async (data: {
        updateType: BatchUpdateTypesEnum;
        invoiceIds: number[];
        deliveryDate?: Date;
    }) => {
        return patch(`${URI}/invoices`, data);
    };

    return {
        indexModules,
        indexModulesFilterOptions,
        indexProduction,
        indexProductionNoParams,
        indexStates,
        indexCities,
        patchInvoice,
        getInvoicePdf,
        getNfeSimpleShipmentPdf,
        getNfeFutureSalePdf,
        getNfeTriangulation,
        getConciseProductionInvoices,
        batchUpdateInvoices,
    };
}
