/* eslint-disable react/destructuring-assignment */
import { Col, Form, Modal, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { InvoiceTriangulationProps } from '../../../../contexts/productionContext';
import { InvoiceTriangulationStatus } from '../../../../utils/constants/invoiceStatus';
import { cnpjMask, cpfMask } from '../../../../utils/text/masks';
import { formatCurrency } from '../../../../utils/text/strings';
import { StyledH5, StyledP, StyledStatusBadge } from './styles';

interface Props {
    invoiceTriangulation: InvoiceTriangulationProps;
    show: boolean;
    onHide(): void;
}

const InvoiceTriangulationModal = (props: Props) => {
    const getStatusText = (status: string) => {
        if (status === InvoiceTriangulationStatus.Approved) {
            return 'TRIANGULAÇÃO LIBERADA';
        }

        if (status === InvoiceTriangulationStatus.Processing) {
            return 'TRIANGULAÇÃO EM PROCESSAMENTO';
        }

        if (status === InvoiceTriangulationStatus.Completed) {
            return 'DADOS INFORMADOS / TRIANGULAÇÃO NÃO LIBERADA';
        }

        if (status === InvoiceTriangulationStatus.Created) {
            return 'DADOS PARCIALMENTE INFORMADOS / TRIANGULAÇÃO NÃO LIBERADA';
        }

        return '';
    };

    const getStyle = (status: string) => {
        if (status === InvoiceTriangulationStatus.Approved) {
            return '#6fcf97';
        }

        return '#febf00';
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Detalhes da Operação de Venda por Conta e Ordem
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {!props.invoiceTriangulation ? (
                    <Row>
                        <Col>
                            <StyledP>
                                Nenhuma informação sobre a operação foi
                                cadastrada para este pedido até o momento
                            </StyledP>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        margin: '10px 0 15px 0',
                                    }}
                                >
                                    <StyledH5>
                                        Pedido #
                                        {props.invoiceTriangulation.invoiceId}
                                    </StyledH5>

                                    {props.invoiceTriangulation.status ? (
                                        <StyledStatusBadge
                                            color={getStyle(
                                                props.invoiceTriangulation
                                                    .status,
                                            )}
                                        >
                                            {getStatusText(
                                                props.invoiceTriangulation
                                                    .status,
                                            )}
                                        </StyledStatusBadge>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formBasicName">
                                    <Form.Label>
                                        Nome/Razão Social do Cliente
                                    </Form.Label>

                                    <Form.Control
                                        name="customerName"
                                        type="text"
                                        value={
                                            props.invoiceTriangulation
                                                .customerName || ''
                                        }
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formBasicDocument">
                                    <Form.Label>CPF/CNPJ do Cliente</Form.Label>

                                    <Form.Control
                                        name="customerDocument"
                                        type="text"
                                        as={MaskedInput}
                                        mask={
                                            props.invoiceTriangulation
                                                .customerDocument.length <= 11
                                                ? cpfMask
                                                : cnpjMask
                                        }
                                        value={
                                            props.invoiceTriangulation
                                                .customerDocument || ''
                                        }
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formBasicAddress">
                                    <Form.Label>Destino da Entrega</Form.Label>

                                    <Form.Control
                                        name="customerAddress"
                                        type="text"
                                        value={
                                            props.invoiceTriangulation
                                                .addressCity &&
                                            props.invoiceTriangulation
                                                .addressState
                                                ? `${props.invoiceTriangulation.addressCity} - ${props.invoiceTriangulation.addressState}`
                                                : ''
                                        }
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formBasicTotalValue">
                                    <Form.Label>
                                        Valor Total da Nota Fiscal
                                    </Form.Label>

                                    <Form.Control
                                        name="totalValue"
                                        type="text"
                                        value={
                                            props.invoiceTriangulation
                                                .totalValue
                                                ? formatCurrency(
                                                      props.invoiceTriangulation
                                                          .totalValue,
                                                  )
                                                : ''
                                        }
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formBasicErpCode">
                                    <Form.Label>
                                        Código do Pedido de Venda
                                    </Form.Label>

                                    <Form.Control
                                        name="erpCode"
                                        type="text"
                                        value={
                                            props.invoiceTriangulation
                                                .erpCode || ''
                                        }
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formBasicNf">
                                    <Form.Label>
                                        Nota Fiscal de Conta e Ordem
                                    </Form.Label>

                                    <Form.Control
                                        name="nf"
                                        type="text"
                                        value={
                                            props.invoiceTriangulation.nf || ''
                                        }
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default InvoiceTriangulationModal;
