/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
    Form,
    InputGroup,
    Modal,
    ModalFooter,
    Table,
    Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { StyledTh } from './styles';
import { BranchValues, useBranch } from '../../../../../contexts/branchContext';
import Pagination from '../../../../Pagination';
import formatMask from '../../../../../utils/formatMasks';
import useBranchesHttpService from '../../../../../services/http/branches-http';

interface Props {
    show: boolean;
    id?: any;
    onHide: Function;
}

const List: React.FC<Props> = (props: Props) => {
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({} as any);
    const [term, setTerm] = useState('');
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [perPage] = useState(5);

    const { branch, setBranch }: BranchValues = useBranch();

    const { readMany } = useBranchesHttpService();

    async function loadBranches(): Promise<void> {
        const params = {
            term,
            skip: page - 1,
            take: perPage,
        };

        const response = await readMany(params);

        setPages(Math.ceil(response.data.metadata.total / perPage));
        setBranches(response.data.results);
    }

    const handleSubmit = async () => {
        try {
            if (
                Object.keys(selectedBranch).length === 0 &&
                selectedBranch.constructor === Object
            ) {
                toast.info('Selecione uma filial');

                return;
            }

            localStorage.setItem('branch', JSON.stringify(selectedBranch));

            setBranch(selectedBranch);

            props.onHide();

            window.location.reload();

            toast.success('Filial alterada com sucesso!');
        } catch (error) {
            toast.error('Houve um erro ao selecionar uma filial.');
        }
    };

    useEffect(() => {
        if (props.show) {
            setPage(1);
            setTerm('');

            loadBranches();

            if (localStorage.branch) {
                setSelectedBranch(JSON.parse(localStorage.branch));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    useEffect(() => {
        if (props.show) {
            const timer = setTimeout(async () => {
                setPage(1);

                loadBranches();
            }, 500);

            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        if (props.show) {
            loadBranches();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <>
            <Modal
                show={props.show}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                onHide={() => props.onHide()}
            >
                <Modal.Header closeButton={branch}>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Selecionar filial
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'ml-3 mr-3'}>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="fas fa-search"></i>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            value={term}
                            onChange={(event) => setTerm(event.target.value)}
                            placeholder="Pesquisar..."
                        />
                    </InputGroup>

                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh></StyledTh>
                                <StyledTh>NOME</StyledTh>
                                <StyledTh>CÓDIGO</StyledTh>
                                <StyledTh>CNPJ</StyledTh>
                            </tr>
                        </thead>
                        <tbody>
                            {branches &&
                                branches.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td>
                                            <Form.Check
                                                checked={
                                                    selectedBranch &&
                                                    selectedBranch.id ===
                                                        item.id
                                                }
                                                disabled={props.id}
                                                type={'radio'}
                                                name="selectedBranch"
                                                value={item.id}
                                                onChange={() => {}}
                                                onClick={() => {
                                                    setSelectedBranch(item);
                                                }}
                                            />
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.branchCode}</td>
                                        <td>
                                            {formatMask(
                                                item.cnpj,
                                                '"##.###.###/####-##',
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>

                    <Pagination
                        pages={pages}
                        page={page}
                        onPageChange={(page: any) => setPage(page)}
                    ></Pagination>
                </Modal.Body>
                <ModalFooter>
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            handleSubmit();
                        }}
                        disabled={JSON.stringify(selectedBranch) === ''}
                    >
                        Selecionar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export { List };
