import { Card, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledMainDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
`;

export const StyledContentDiv = styled.div`
    height: 100%;
    background-color: #f7f7f7;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;

    hr {
        margin-left: 0;
        margin-right: 0;
    }

    @media (max-width: 767px) {
        flex-basis: 80%;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        flex-basis: 44%;
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
        flex-basis: 37%;
    }

    @media (min-width: 1280px) and (max-width: 1439px) {
        flex-basis: 30%;
    }
`;

export const StyledHeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    color: #545454;
    font-size: 24px;

    span {
        font-size: 28px;
        font-weight: 700;
        font-family: Branding;
        line-height: 17px;
        height: 24px;
    }

    *:hover {
        cursor: pointer;
        color: #7000d1;
    }
`;

export const StyledUserDiv = styled.div`
    height: 63px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledUsernameDiv = styled.div`
    flex-grow: 1;
    margin-left: 15px;
    display: flex;
    flex-direction: column;

    p {
        color: #545454;
    }

    p:first-child {
        font-weight: bold;
        font-size: 16px !important;
        margin-bottom: 5px;
    }

    p:nth-child(2) {
        margin: 0;
    }
`;

export const StyledCardHeader = styled(Card.Header)`
    margin-left: 0;
    padding: 0;
    min-height: 60px;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    color: #545454;
    border: none;

    span {
        margin-left: 10px;
    }

    :hover {
        cursor: pointer;
        color: #7000d1;
        background-color: #f0f0f0;
    }
`;

export const StyledCardBody = styled(Card.Body)`
    display: flex;
    flex-direction: column;
    padding: 0 0 0 34px;
    background-color: #f7f7f7;
    color: #545454;
    font-size: 24px;

    a {
        min-height: 60px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #545454;
    }

    span {
        font-size: 16px !important;
        margin-left: 10px;
    }

    a:hover {
        color: #7000d1;
        background-color: #f0f0f0;
    }
`;

export const StyledListDiv = styled.div`
    height: 45%;
    overflow-y: auto;
    margin-bottom: 40px;
    padding-left: 0px;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #eaeaea;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #b8b8b8;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #a1a1a1;
    }
`;

export const StyledOptionDiv = styled(NavDropdown.Item)`
    display: flex;
    align-items: center;
    color: #545454;
    font-size: 24px;
    min-height: 60px;
    padding-left: 0px;

    span {
        margin-left: 10px;
        font-size: 16px !important;
    }

    :hover {
        cursor: pointer;
        color: #7000d1;
        background-color: #f0f0f0;
    }
`;

export const StyledFillerDiv = styled.div`
    height: 100%;
    background-color: #32292f99;
    flex-grow: 1;
`;
