/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
import { API_URL } from '../../config/urls';
import useRequest from './request';

export function useCompanyHttpService() {
    const { get, post, del } = useRequest({ baseURL: API_URL });

    const URI = '/api/companies';

    const show = async (id: any) => {
        return await get(`${URI}/${id}`);
    };

    const index = async (data: any) => {
        return await get(`${URI}`, data);
    };

    const indexCompanies = async () => {
        return await get(`${URI}`);
    };

    const indexStatuses = async (data: any) => {
        return await get(`${URI}/${data.companyId}/statuses`, data);
    };

    const indexIntegrations = async (data: any) => {
        return await get(`${URI}/${data.companyId}/integrations`, data);
    };

    const save = async (data: any) => {
        return await post(`${URI}`, data);
    };

    const saveStatus = async (data: any) => {
        return await post(`${URI}/${data.companyId}/statuses`, data);
    };

    const saveIntegration = async (data: any) => {
        return await post(`${URI}/${data.companyId}/integrations`, data);
    };

    const remove = async (data: any) => {
        return await del(`${URI}/${data.companyId}`);
    };

    const removeStatus = async (data: any) => {
        return await del(`${URI}/statuses/${data.statusId}`);
    };

    const removeIntegration = async (data: any) => {
        return await del(`${URI}/integrations/${data.integrationId}`);
    };

    return {
        show,
        index,
        indexCompanies,
        indexStatuses,
        indexIntegrations,
        save,
        saveStatus,
        saveIntegration,
        remove,
        removeStatus,
        removeIntegration,
    };
}
