/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export default function typeSafeJsonParse<T>(text: string): T | undefined {
    try {
        const jsonValue: T = JSON.parse(text);

        return jsonValue;
    } catch {
        return undefined;
    }
}
