import { IAuthAction, IAuthInitialState } from '../interfaces';
import ActionType from '../enums/action-type-enum';
import userHasRoles from '../../../utils/userHasRoles';
import { Role } from '../../../constants/role';

export default function authReducer(
    state: IAuthInitialState,
    action: IAuthAction,
) {
    const { type, payload } = action;
    switch (type) {
        case ActionType.Login:
            return {
                ...state,
                isAuthenticated: userHasRoles(payload.user, [
                    Role.ShippingCompany,
                    Role.Logistics,
                    Role.Administrator,
                    Role.Commercial,
                    Role.CommercialSupervisor,
                    Role.CustomerSuccess,
                    Role.Production,
                ]),
                user: payload.user,
            } as IAuthInitialState;
        case ActionType.Logout:
            return {
                ...state,
                isAuthenticated: false,
                user: {},
            } as IAuthInitialState;
        default:
            throw new Error();
    }
}
