// Função correta para formatação de acordo com o timezone correto

export default function formatDateToSPTimeZone(dateToFormat: string) {
    if (dateToFormat === null || dateToFormat === '') {
        return 'Não Disponível';
    }

    const date = new Date(dateToFormat);

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const result = `${day}/${month}/${date.getFullYear()}`;

    return result;
}
