/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
import { API_URL } from '../../config/urls';
import useRequest from './request';

export function useStatusHttpService() {
    const { get } = useRequest({ baseURL: API_URL });

    const URI = 'api/statuses';

    const index = async () => {
        return await get(`${URI}`);
    };

    return {
        index,
    };
}
