import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const StyledController = styled(MaskedInput)`
    height: 48px;
    font-size: 0.75rem;
    color: #707070;
    display: block;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;

    &::placeholder {
        color: #e1e1e4;
    }
`;

export default StyledController;
