import { AUTH_API_URL } from '../../config/urls';
import useRequest from './request';

export default function useBranchesHttpService() {
    const { get } = useRequest({ baseURL: AUTH_API_URL });

    const uri = '/branches';

    const readMany = async (params: {
        skip?: number;
        take?: number;
        term?: string;
        cnpj?: string;
    }) => {
        return get(`${uri}`, params);
    };

    return {
        readMany,
    };
}
