import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import BaseLayout from '../../components/BaseLayout';
import { useCompany } from '../../contexts/companyContext';
import { CompanyEditRoutePath } from '../../routes/config';
import {
    StyledPageSubTitle,
    StyledPageTitle,
} from '../../utils/theme/pageTitle';
import CompanyTable from './components/CompanyTable';
import { CompanyFilter } from './components/Filters';

const List: React.FC = () => {
    const history = useHistory();

    const { setSelectedCompany } = useCompany();

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Transportadoras
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Cadastro e gerenciamento de transportadoras parceiras
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <Button
                        className="float-right w-25"
                        onClick={() => {
                            setSelectedCompany({});
                            history.push(CompanyEditRoutePath);
                        }}
                    >
                        <i className="fas fa-plus" /> Nova Transportadora
                    </Button>
                </Col>
            </Row>

            <CompanyFilter />

            <CompanyTable />
        </BaseLayout>
    );
};

export default List;
