/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-fragments */
/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCompany } from '../../../../contexts/companyContext';
import { useCompanyHttpService } from '../../../../services/http/company-http';
import { StyledRow, StyledSubtitle } from './styles';

interface FormData {
    key: string;
    value: any;
}

const IntegrationDetail = (props: any) => {
    const {
        setShowIntegrationDetailModal,
        setSelectedIntegration,
        selectedIntegration,
        selectedCompany,
    } = useCompany();
    const [loading, setLoading] = useState(false);
    const { saveIntegration } = useCompanyHttpService();

    const { register, getValues, setValue, reset } = useForm<FormData>({
        defaultValues: { key: '', value: '' },
    });

    async function saveCompanyIntegration() {
        setLoading(true);

        try {
            const values = getValues();

            console.log('values -> ', values);

            const localStorageJson = JSON.parse(
                localStorage.getItem('branch') || '',
            );

            const integration = {
                id: selectedIntegration?.id,
                companyId: selectedCompany.id,
                key: values.key,
                value: values.value,
                erpTenantId:
                    localStorageJson?.companyCode +
                    ',' +
                    localStorageJson.branchCode,
            };

            await saveIntegration(integration);

            toast.success('Chave salva.');
        } catch (error) {
            toast.error('Erro ao salvar chave.');
        }

        setLoading(false);
    }

    useEffect(() => {
        if (selectedIntegration) {
            setValue('key', selectedIntegration.key);
            setValue('value', selectedIntegration.value);
        } else {
            reset();
        }
    }, [selectedIntegration]);

    return (
        <React.Fragment>
            <Modal
                className={'modal-dialog-scrollable'}
                {...props}
                size="xl"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Chave de Integração
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'w-100'}>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledSubtitle>Chave</StyledSubtitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="key"
                                type="text"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledSubtitle>Valor</StyledSubtitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="value"
                                type="text"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1 mt-5">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowIntegrationDetailModal(false);
                                    setSelectedIntegration({});
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => {
                                    saveCompanyIntegration();
                                    setShowIntegrationDetailModal(false);
                                    setSelectedIntegration({});
                                }}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation={'border'} size={'sm'} />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className={'ml-3 mr-3 mb-2 ,t'}></Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default IntegrationDetail;
