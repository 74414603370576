import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import Edit from '../../../pages/Companies/Edit';
import List from '../../../pages/Companies/List';

export const CompaniesRoutePath = '/Transportadoras';
export const CompanyEditRoutePath = '/Transportadoras/editar';

export const CompaniesRoutesComponent: React.FC = () => (
    <Switch>
        <PrivateRoute path={CompaniesRoutePath} component={List} exact />
        <PrivateRoute path={CompanyEditRoutePath} component={Edit} exact />
    </Switch>
);
