/* eslint-disable @typescript-eslint/no-shadow */
export const control = (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? '1px solid rgba(47, 204, 139, 0.233)' : null,
    borderRadius: 3,
    boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
    minHeight: 48,
    backgroundColor: state.isDisabled ? '#e9ecef' : '#ffffff',
    fontSize: 12,
});

export const tradeControl = (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? '1px solid rgba(47, 204, 139, 0.233)' : null,
    borderRadius: 3,
    boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
    minHeight: 48,
    backgroundColor: state.isDisabled ? '#e9ecef' : '#ffffff',
    fontSize: 12,
});

export const tradeControlInvalid = (base: any, state: any) => ({
    ...base,
    border: '1px solid rgba(220, 53, 69, 0.5)',
    borderRadius: 3,
    boxShadow: state.isFocused ? '0px 0px 6px rgba(0, 0, 0, 0.30)' : null,
    minHeight: 48,
    backgroundColor: state.isDisabled ? '#e9ecef' : '#ffffff',
    fontSize: 12,
});

export const optionsTheme = (base: any) => ({
    ...base,
    color: '#000000',
});

export const theme = (theme: any) => ({
    ...theme,

    colors: {
        ...theme.colors,
        primary: 'rgba(47, 204, 139, 0.1)',
        primary25: 'rgba(47, 204, 139, 0.1)',

        boxShadow: 'none',
    },
});
