/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import Highlighter from 'react-highlight-words';

import { Button, Col, Row, Dropdown, Spinner, Table } from 'react-bootstrap';
import { StyledTh } from './styles';

import { ReactComponent as More } from '../../../../assets/images/icons/more.svg';
import { ReactComponent as List } from '../../../../assets/images/icons/list.svg';

import { useInvoice } from '../../../../contexts/invoiceContext';
import { useInvoiceHttpService } from '../../../../services/http/invoice-http';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import { DashboardRoutePath } from '../../../../routes/config';
import { useAuth } from '../../../../hooks/use-auth';

import formatDate from '../../../../utils/localization/formatDate';
import { rowsPerPage } from '../../../../constants/pagination';

import userHasRoles from '../../../../utils/userHasRoles';
import { Role } from '../../../../constants/role';
import formatDateToSPTimeZone from '../../../../utils/localization/formatDateToSPTimeZone';
import { useProduction } from '../../../../contexts/productionContext';

const InvoiceTable = (props: any) => {
    const history = useHistory();

    const {
        formData: filter,
        setSelectedInvoice,
        reset,
        setShowDetailModal,
    } = useInvoice();

    const { companyOptions } = useProduction();

    const { index } = useInvoiceHttpService();

    const { user } = useAuth();

    const loadMoreRef = useRef(null);

    const loadInvoices = async ({ pageParam = 0 }: any) => {
        const statusFilter =
            filter.statuses && filter.statuses.map((item: any) => item.value);

        const companyFilter =
            filter.companies && filter.companies.map((item: any) => item.value);

        const redispatchCompanyFilter =
            filter.redispatchCompanies &&
            filter.redispatchCompanies.map((item: any) => item.value);

        const sellerFilter =
            filter.seller && filter.seller.map((item: any) => item.value);

        const localStorageJson = JSON.parse(
            localStorage.getItem('branch') || '',
        );

        const params = {
            term: filter.term ? filter.term : '',
            skip: pageParam,
            take: rowsPerPage,
            statusIds: statusFilter?.join(',') ?? '',
            companyIds: companyFilter?.join(',') ?? '',
            reDispatchCompanyIds: redispatchCompanyFilter?.join(',') ?? '',
            estimatedDeliveryDate: filter.date ?? '',
            estimatedDeliveryEndDate: filter.endDate ?? '',
            cnpj: userHasRoles(user, [Role.ShippingCompany]) ? user.cnpj : '',
            erpTenantId:
                localStorageJson?.companyCode +
                ',' +
                localStorageJson.branchCode,
            sellerIds: sellerFilter.join(',') ?? '',
        };

        const response: any = await index(params);

        return {
            data: response.data.data,
            nextPage: pageParam,
        };
    };

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useInfiniteQuery('invoices', loadInvoices, {
            getNextPageParam: (lastPage) => lastPage.nextPage + 1,
        });

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 500);

        return () => clearTimeout(timer);
    }, [filter, refetch, reset]);

    const findActualOcurrenceDate = (item: any) => {
        const actualOcurrenceDateToSave = item?.invoiceUpdates?.find(
            (update: any) =>
                update.description === 'Entregue' && update.code === '2',
        );
        if (actualOcurrenceDateToSave) {
            return formatDate(actualOcurrenceDateToSave?.createdAt);
        }
    };

    function getCompanyName(erpCode: string) {
        const company = companyOptions.find(
            (company: any) => company.value === erpCode,
        );

        return company?.label ?? '-';
    }

    return (
        <>
            <Row>
                <Col className="ml-2 mr-2">
                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh>Nº DO PEDIDO PORTAL</StyledTh>
                                <StyledTh>NOME DO CLIENTE</StyledTh>
                                <StyledTh>INTEGRADOR</StyledTh>
                                <StyledTh>NOTA FISCAL - SERIE</StyledTh>
                                <StyledTh>TRANSPORTADORA</StyledTh>
                                <StyledTh>TRANSPORTADORA REDESPACHO</StyledTh>
                                <StyledTh>STATUS</StyledTh>
                                <StyledTh>DATA SAÍDA DE FÁBRICA</StyledTh>
                                <StyledTh>PREVISÃO DE ENTREGA</StyledTh>
                                <StyledTh>DATA EFETIVA DA ENTREGA</StyledTh>
                            </tr>
                        </thead>

                        <tbody>
                            {data?.pages &&
                                data?.pages.map((group: any) =>
                                    group?.data.map(
                                        (item: any, index2: any) => (
                                            <tr key={index2}>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={item?.portalId.toString()}
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={
                                                            item?.customerName
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={
                                                            item?.sellerName ||
                                                            '--'
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={`${item?.nfNumber.toString()}-${item?.nfSeries.toString()}`}
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={
                                                            item?.company?.name
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={getCompanyName(
                                                            item?.reDispatchCompanyId,
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={
                                                            item?.lastStatus
                                                        }
                                                    />
                                                </td>

                                                <td>
                                                    {item?.waybillDate
                                                        ? formatDate(
                                                              item?.waybillDate,
                                                          )
                                                        : '--'}
                                                </td>

                                                <td>
                                                    {item?.estimatedDeliveryDate
                                                        ? formatDateToSPTimeZone(
                                                              item?.estimatedDeliveryDate,
                                                          )
                                                        : '--'}
                                                </td>

                                                <td>
                                                    {item?.ocurrenceDate &&
                                                    item?.lastStatus ===
                                                        'Entregue'
                                                        ? formatDate(
                                                              item?.ocurrenceDate,
                                                          )
                                                        : '--'}
                                                </td>

                                                <td className={'d-flex'}>
                                                    {}
                                                    <Dropdown key="left">
                                                        <Dropdown.Toggle
                                                            bsPrefix="nexen"
                                                            as={Button}
                                                            variant="text"
                                                        >
                                                            <More
                                                                width="10"
                                                                height="20"
                                                            />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setSelectedInvoice(
                                                                        item,
                                                                    );
                                                                    setShowDetailModal(
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                <List
                                                                    width="18"
                                                                    height="18"
                                                                    className="ml-n3 mr-2"
                                                                />
                                                                Detalhes do
                                                                pedido
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ),
                                    ),
                                )}
                        </tbody>
                    </Table>
                </Col>

                <Col md={12} className="text-center" ref={loadMoreRef}>
                    {isFetchingNextPage ? (
                        <Col md={12} className="text-center">
                            <Spinner animation={'border'} />
                        </Col>
                    ) : (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>
        </>
    );
};

export { InvoiceTable };
