/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-fragments */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useCompany } from '../../../../contexts/companyContext';
import { useCompanyHttpService } from '../../../../services/http/company-http';
import { useStatusHttpService } from '../../../../services/http/status-http';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../utils/theme/react-select-config';
import { StyledRow, StyledSubtitle } from './styles';

interface FormData {
    code: string;
    status: any;
}

const StatusDetail = (props: any) => {
    const {
        setShowStatusDetailModal,
        setSelectedStatus,
        selectedStatus,
        selectedCompany,
    } = useCompany();
    const [loading, setLoading] = useState(false);
    const { index } = useStatusHttpService();
    const { saveStatus } = useCompanyHttpService();
    const [statuses, setStatuses] = useState([]);

    async function loadStatuses() {
        const response: any = await index();

        const statuses = response.data.data.map((item: any) => ({
            label: item.desc,
            value: item.id,
        }));

        setStatuses(statuses);
    }

    const { register, getValues, setValue, reset, control } = useForm<FormData>(
        {
            defaultValues: { code: '', status: {} },
        },
    );

    async function saveCompanyStatus() {
        setLoading(true);

        try {
            const values = getValues();

            const status = {
                id: selectedStatus?.id,
                companyId: selectedCompany.id,
                code: values.code,
                statusId: values.status.value,
            };

            await saveStatus(status);

            toast.success('Status salvo.');
        } catch (error) {
            toast.error('Erro ao salvar status.');
        }

        setLoading(false);
    }

    useEffect(() => {
        loadStatuses();

        if (selectedStatus) {
            setValue('code', selectedStatus.code);
            setValue('status', {
                label: selectedStatus.status?.desc,
                value: selectedStatus.status?.id,
            });
        } else {
            reset();
        }
    }, [selectedStatus]);

    return (
        <React.Fragment>
            <Modal {...props} size="xl" centered>
                <Modal.Header>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Status de Transporte
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'w-100'}>
                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledSubtitle>
                                Código da Transportadora
                            </StyledSubtitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="code"
                                type="text"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-4 mr-1">
                        <Col>
                            <StyledSubtitle>Status</StyledSubtitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Controller
                                isClearable={false}
                                className="form-control-nexen"
                                as={Select}
                                control={control}
                                name="status"
                                options={statuses}
                                isMulti={false}
                                styles={{
                                    control: tradeControl,
                                    option: optionsTheme,
                                }}
                                theme={theme}
                                placeholder="Selecione..."
                                defaultValue={null}
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1 mt-5">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowStatusDetailModal(false);
                                    setSelectedStatus({});
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => {
                                    saveCompanyStatus();
                                    setShowStatusDetailModal(false);
                                    setSelectedStatus({});
                                }}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation={'border'} size={'sm'} />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className={'ml-3 mr-3 mb-2 ,t'}></Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default StatusDetail;
