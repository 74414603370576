import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { cnpjMask } from '../../../../utils/text/masks';
import StyledController from './styles';

const CompanyData = () => {
    const { register, control } = useFormContext();

    return (
        <>
            <Form.Group controlId="formBasicId">
                <Form.Label>ID PROTHEUS</Form.Label>
                <Form.Control
                    ref={register}
                    name="protheusId"
                    type="text"
                    className="nexen-form-control"
                    placeholder="Informe o Id Protheus"
                    maxLength={6}
                />
            </Form.Group>

            <Form.Group controlId="formBasicName">
                <Form.Label>NOME</Form.Label>
                <Form.Control
                    ref={register}
                    name="name"
                    className="nexen-form-control"
                    placeholder="Informe o nome"
                    maxLength={255}
                />
            </Form.Group>

            <Form.Group controlId="formBasicCnpj">
                <Form.Label>CNPJ</Form.Label>
                <Controller
                    control={control}
                    name="cnpj"
                    render={({ value, onChange }) => (
                        <StyledController
                            ref={register}
                            mask={cnpjMask}
                            className="nexen-form-control"
                            placeholder="Informe o CNPJ"
                            value={value}
                            onChange={onChange}
                            minLength={14}
                        />
                    )}
                />
            </Form.Group>
        </>
    );
};

export default CompanyData;
