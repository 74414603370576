import { Row, Col } from 'react-bootstrap';
import {
    StyledPageSubTitle,
    StyledPageTitle,
} from '../../utils/theme/pageTitle';
import BaseLayout from '../../components/BaseLayout';
import BatchUpdateForm from './components/BatchUpdateForm';

export default function Update() {
    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Atualização em Lote
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Atualização em lote dos dados de produção dos pedidos
                    </StyledPageSubTitle>
                </Col>
            </Row>

            <BatchUpdateForm />
        </BaseLayout>
    );
}
