/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-brace-presence */
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import {
    StyledCard,
    StyledCardBody,
    StyledCardText,
    StyledGrid,
} from './styles';
import BaseLayout from '../../components/BaseLayout';
import RolesGate from '../../components/RolesGate';
import cards from '../../constants/cards';

export function List() {
    const history = useHistory();

    return (
        <BaseLayout>
            <StyledGrid>
                {cards.map((card, index) => (
                    <RolesGate allowedRoles={card.allowedRoles} key={index}>
                        <StyledCard
                            onClick={async () => {
                                history.push(card.to);
                            }}
                        >
                            <StyledCardBody>
                                <Row className="align-items-center">
                                    <Col className={'my-4'} md={3}>
                                        {card.icon}
                                    </Col>
                                    <Col className={'my-4'}>
                                        <Card.Subtitle className="mb-2 text-muted">
                                            <strong>{card.name}</strong>
                                        </Card.Subtitle>
                                        <StyledCardText>
                                            {card.text}
                                        </StyledCardText>
                                    </Col>
                                </Row>
                            </StyledCardBody>
                        </StyledCard>
                    </RolesGate>
                ))}
            </StyledGrid>
        </BaseLayout>
    );
}
