import { ButtonGroup } from 'react-bootstrap';
import styled from 'styled-components';

const StyledButtonGroup = styled(ButtonGroup)`
    @media (min-width: 320px) and (max-width: 480px) {
        min-width: 100%;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        min-width: 60%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        min-width: 40%;
    }

    @media (min-width: 1025px) and (max-width: 1400px) {
        min-width: 30%;
    }

    @media (min-width: 1401px) {
        min-width: 20%;
    }
`;

export default StyledButtonGroup;
