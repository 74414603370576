import styled from 'styled-components';

interface Props {
    invoiceId: number;
    selectedInvoiceId: number;
}

export const StyledTh = styled.th`
    background-color: #f2f2f2;
`;

export const StyledGreenTh = styled.th`
    background: #c9dfca !important;
`;

export const StyledGreenTd = styled.td<Props>`
    background: ${(props: any) =>
        props?.invoiceId === props?.selectedInvoiceId ? '#fff888' : '#c9dfca'};
`;

export const StyledTr = styled.tr<Props>`
    background: ${(props: any) =>
        props?.invoiceId === props?.selectedInvoiceId
            ? '#fff888'
            : '#ffffff'} !important;
`;

export const StyledPageTitle = styled.h1`
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 16px;
    color: #484848;
`;

export const StyledSubtitle = styled.h3`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    color: #707070;
`;

export const StyledTdText = styled.h5`
    font-weight: normal;
    font-size: 0.9rem;
    letter-spacing: 0.05em;
    color: #828282;
`;
