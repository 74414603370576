/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useAuth } from '../../../hooks/use-auth';
import Accounts from '../../config/Accounts';

interface Props extends RouteProps {
    component?: any;
    isPrivate?: any;
    redirectIfNotExact?: any;
    path?: any;
    redirectTo?: any;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
    const { isAuthenticated, isLoading } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoading ? (
                    <Row className={'mt-5'}>
                        <Col sm={6} />
                        <Col>
                            <Spinner animation="border" />
                        </Col>
                    </Row>
                ) : isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Accounts />
                )
            }
        />
    );
};

export default PrivateRoute;
