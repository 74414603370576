import deliveryTruck from '../assets/images/icons/delivery-truck.svg';
import house from '../assets/images/icons/house.svg';
import inventory from '../assets/images/icons/inventory.svg';
import invoicesIcon from '../assets/images/icons/invoices.svg';
import Page from '../shared/interfaces/page.interface';
import { Role } from './role';

const pages: Page[] = [
    {
        icon: house,
        sidebarIcon: 'PiHouse',
        text: 'Início',
        path: '/dashboard',
        roles: [],
    },
    {
        icon: invoicesIcon,
        sidebarIcon: 'PiPackage',
        text: 'Pedidos',
        path: '/pedidos',
        roles: [],
    },
    {
        icon: deliveryTruck,
        sidebarIcon: 'PiTruck',
        text: 'Transportadoras',
        path: '/Transportadoras',
        roles: [
            Role.Administrator,
            Role.Commercial,
            Role.CommercialSupervisor,
            Role.CustomerSuccess,
            Role.Financial,
            Role.Logistics,
            Role.PointsAdministrator,
            Role.TechnicalSupport,
            Role.Production,
        ],
    },
    {
        icon: inventory,
        sidebarIcon: 'PiClipboard',
        text: 'Produção',
        path: '/produção',
        roles: [
            Role.Administrator,
            Role.Commercial,
            Role.CommercialSupervisor,
            Role.CustomerSuccess,
            Role.Financial,
            Role.Logistics,
            Role.PointsAdministrator,
            Role.TechnicalSupport,
            Role.Production,
        ],
    },
];

export default pages;
