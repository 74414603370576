/* eslint-disable react/jsx-props-no-spreading */
import { useState, createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';

const CompanyContext = createContext<any>({});
const useCompany = () => useContext(CompanyContext);

interface filters {
    term: string;
}

function CompanyProvider(props: any) {
    const [selectedCompany, setSelectedCompany] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});
    const [selectedIntegration, setSelectedIntegration] = useState({});
    const [showStatusDetailModal, setShowStatusDetailModal] = useState(false);
    const [showIntegrationDetailModal, setShowIntegrationDetailModal] =
        useState(false);

    const { control, watch, reset, formState } = useForm<filters>({
        shouldUnregister: false,
        defaultValues: { term: '' },
    });

    const formData = watch();

    return (
        <CompanyContext.Provider
            value={{
                selectedCompany,
                setSelectedCompany,
                selectedStatus,
                setSelectedStatus,
                selectedIntegration,
                setSelectedIntegration,
                showStatusDetailModal,
                setShowStatusDetailModal,
                showIntegrationDetailModal,
                setShowIntegrationDetailModal,
                control,
                watch,
                reset,
                formState,
                formData,
            }}
            {...props}
        />
    );
}

export { CompanyProvider, useCompany };
