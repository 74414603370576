import { Form } from 'react-bootstrap';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { BatchUpdateFormFields } from '../BatchUpdateForm';
import StyledDatePicker from './styles';

interface DeliveryDateFormProps {
    control: Control<BatchUpdateFormFields>;
    errors: DeepMap<BatchUpdateFormFields, FieldError>;
    disabled?: boolean;
}

export default function DeliveryDateForm({
    control,
    errors,
    disabled,
}: DeliveryDateFormProps) {
    return (
        <Form.Group className="mb-3" controlId="formDeliveryDate">
            <Form.Label>Data de produção</Form.Label>
            <Controller
                control={control}
                name="deliveryDate"
                render={({ onChange, onBlur, value }) => (
                    <StyledDatePicker isInvalid={Boolean(errors.deliveryDate)}>
                        <ReactDatePicker
                            className="form-control"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            disabled={disabled}
                            minDate={new Date()}
                        />
                    </StyledDatePicker>
                )}
                rules={{ required: 'Campo obrigatório' }}
            />

            {errors.deliveryDate && (
                <Form.Text className="text-danger">Campo obrigatório</Form.Text>
            )}
        </Form.Group>
    );
}
