import { SelectOption } from '../components/Select';

export enum BatchUpdateTypesEnum {
    DeliveryDate = 'DELIVERY_DATE',
    ProductionActive = 'PRODUCTION_ACTIVE',
    ProductionPending = 'PRODUCTION_PENDING',
}

const BatchUpdateTypes = Object.values(BatchUpdateTypesEnum);

const BatchUpdateTypeToLabelMapping: Record<BatchUpdateTypesEnum, string> = {
    [BatchUpdateTypesEnum.DeliveryDate]: 'Atualizar data de produção do pedido',
    [BatchUpdateTypesEnum.ProductionActive]:
        'Atualizar estado do pedido para produção',
    [BatchUpdateTypesEnum.ProductionPending]:
        'Atualizar estado do pedido para pendente',
};

export const BatchUpdateOptions: Array<SelectOption<string>> =
    BatchUpdateTypes.map((type) => ({
        label: BatchUpdateTypeToLabelMapping[type],
        value: type,
    }));
