/* eslint-disable import/prefer-default-export */
export enum Role {
    Administrator = 'ADMINISTRATOR',
    PointsAdministrator = 'POINTS_ADMINISTRATOR',
    TechnicalSupport = 'TECHNICAL_SUPPORT',
    Integrator = 'INTEGRATOR',
    Commercial = 'COMMERCIAL',
    Financial = 'FINANCIAL',
    Logistics = 'LOGISTICS',
    CommercialSupervisor = 'COMMERCIAL_SUPERVISOR',
    CustomerSuccess = 'CUSTOMER_SUCCESS',
    ShippingCompany = 'SHIPPING_COMPANY',
    Production = 'PRODUCTION',
}
