import { useQuery } from 'react-query';
import { SelectOption } from '../components/Select';
import usePortalHttpService from '../services/http/portal-http';
import ConciseProductionInvoice from '../shared/interfaces/conciseProductionInvoice.interface';
import typeSafeJsonParse from '../utils/typeSafeJsonParse';

interface useConciseProductionInvoicesResponse {
    invoices?: Array<SelectOption<number>>;
    isLoading: boolean;
}

const minSearchLength = 3;

export default function useConciseProductionInvoices(
    searchTerm: string,
): useConciseProductionInvoicesResponse {
    const { getConciseProductionInvoices } = usePortalHttpService();

    const loadConciseProductionInvoices = async (): Promise<
        ConciseProductionInvoice[]
    > => {
        const branchValue = localStorage.getItem('branch');

        if (!branchValue) {
            throw Error('Filial não encontrada');
        }

        const branch = typeSafeJsonParse<{
            companyCode: string;
            branchCode: string;
        }>(branchValue);

        if (!branch) {
            throw Error('Filial não identificada');
        }

        const { data } = await getConciseProductionInvoices({
            tenantId: `${branch.companyCode},${branch.branchCode}`,
            searchTerm,
        });

        return data;
    };

    const { data, isLoading } = useQuery({
        queryKey: ['conciseProductionInvoices', searchTerm],
        queryFn: loadConciseProductionInvoices,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        enabled: searchTerm.length >= minSearchLength,
    });

    const invoices: Array<SelectOption<number>> | undefined = data?.map(
        (conciseProductionInvoice) => {
            return {
                value: conciseProductionInvoice.id,
                label: `Pedido ${conciseProductionInvoice.id} - Código ERP ${
                    conciseProductionInvoice.erpCode || 'não identificado'
                }`,
            };
        },
    );

    return { invoices, isLoading };
}
