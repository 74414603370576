import ReactSelect, {
    ActionMeta,
    GroupBase,
    InputActionMeta,
    MultiValue,
    OptionsOrGroups,
    PropsValue,
    SingleValue,
} from 'react-select';
import {
    optionsTheme,
    theme,
    tradeControl,
    tradeControlInvalid,
} from '../../utils/theme/react-select-config';

export interface SelectOption<T> {
    value: T;
    label: string;
}

interface SelectProps<T> {
    options?: OptionsOrGroups<SelectOption<T>, GroupBase<SelectOption<T>>>;
    placeholder?: string;
    name?: string;
    onChange?: (
        newValue: MultiValue<SelectOption<T>> | SingleValue<SelectOption<T>>,
        actionMeta: ActionMeta<SelectOption<T>>,
    ) => void;
    value?: PropsValue<SelectOption<T>>;
    isMulti?: boolean;
    className?: string;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isClearable?: boolean;
    isLoading?: boolean;
    inputValue?: string;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    noOptionsMessage?: string;
}

export default function Select<T>({
    options,
    placeholder = 'Selecione...',
    name,
    onChange,
    value,
    isMulti,
    className,
    isDisabled,
    isInvalid,
    isClearable,
    isLoading,
    inputValue,
    onInputChange,
    noOptionsMessage = 'Nenhuma opção encontrada',
}: SelectProps<T>) {
    return (
        <ReactSelect
            placeholder={placeholder}
            noOptionsMessage={() => noOptionsMessage}
            name={name}
            onChange={onChange}
            value={value}
            options={options}
            isMulti={isMulti}
            className={className}
            styles={{
                control: isInvalid ? tradeControlInvalid : tradeControl,
                option: optionsTheme,
            }}
            theme={theme}
            isDisabled={isDisabled}
            isClearable={isClearable}
            isLoading={isLoading}
            loadingMessage={() => 'Carregando...'}
            inputValue={inputValue}
            onInputChange={onInputChange}
        />
    );
}
