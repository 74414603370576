/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Col, Modal, Row, Button, Spinner, Image } from 'react-bootstrap';

import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

import fileUploadImg from '../../../../assets/images/icons/fileUpload.png';
import { useInvoice } from '../../../../contexts/invoiceContext';
import { StyledRow } from './styles';

const ReceiptModal = (props: any) => {
    const [loading, setLoading] = useState(false);
    const { setShowReceiptModal, setHasUploadedReceipt, setFileData } =
        useInvoice();

    const [data, setData]: any = useState();

    async function saveReceipt() {
        setLoading(true);

        try {
            let formData = new FormData();

            formData.append('file', data.get('file'));

            setFileData(formData);

            setHasUploadedReceipt(true);
            setShowReceiptModal(false);

            toast.success('Comprovante adicionado.');
        } catch (error) {
            toast.error('Erro ao adicionar comprovante.');
        }

        setLoading(false);
    }

    return (
        <React.Fragment>
            <Modal
                className={'modal-dialog-scrollable'}
                {...props}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Comprovante
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'w-100'}>
                    <Row className={'mb-3 ml-1 mr-1'}>
                        <Dropzone
                            accept={['image/png', 'image/jpeg']}
                            onDropRejected={() => {
                                toast.error(
                                    'Arquivo muito grande ou formato incorreto',
                                );
                            }}
                            maxSize={5242880}
                            onDrop={(file: any) => {
                                let data = new FormData();
                                data.append('file', file[0], file[0].name);
                                setData(data);
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="custom-file-upload">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Row className={'dropzone-title'}>
                                            <Col
                                                className={'dropzone-title'}
                                                sm={8}
                                            >
                                                <h5
                                                    style={{
                                                        color: '#F2994A',
                                                        fontSize: '1rem',
                                                    }}
                                                    className={
                                                        'dropzone-title p-4'
                                                    }
                                                >
                                                    Clique aqui para selecionar
                                                    um arquivo ou arraste-o
                                                </h5>
                                            </Col>
                                            <Col className="text-right">
                                                {loading ? (
                                                    <Spinner
                                                        className={'mr-4 mt-4'}
                                                        animation={'border'}
                                                    />
                                                ) : (
                                                    <Image
                                                        className={'mr-4 mt-3'}
                                                        src={fileUploadImg}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className={'mt-n4 ml-4'}>
                                            <h6
                                                style={{
                                                    fontSize: '0.813rem',
                                                }}
                                            >
                                                arquivos jpg e pngs até 5 MB
                                            </h6>
                                        </Row>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Row>
                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                disabled={loading}
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    setShowReceiptModal(false);
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                disabled={loading}
                                className="w-100 float-right"
                                onClick={() => {
                                    saveReceipt();
                                }}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation={'border'} size={'sm'} />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className={'ml-3 mr-3 mb-2'}></Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ReceiptModal;
