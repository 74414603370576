/* eslint-disable react/jsx-props-no-spreading */
import { useState, createContext, useContext, useEffect } from 'react';
import { getSelectedBranch } from '../utils/getSelectedBranch';

export interface BranchValues {
    branch: any;
    setBranch: any;
}

const BranchContext = createContext<any>({});
const useBranch = () => useContext(BranchContext);

function BranchProvider(props: any) {
    const [branch, setBranch] = useState({} as any);

    useEffect(() => {
        if (!branch?.id) {
            setBranch(getSelectedBranch());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <BranchContext.Provider value={{ branch, setBranch }} {...props} />;
}

export { BranchProvider, useBranch };
