/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/require-default-props */
import React, { cloneElement, ReactNode } from 'react';
import { useAuth } from '../../hooks/use-auth';
import { IRole } from '../../interfaces/role/role';

interface Props {
    userRoles?: { reference: string }[];
    allowedRoles: string[];
    renderNoAccess?: ReactNode;
    noAccessProps?: object;
    children: ReactNode;
}

const hasRole = (
    userRoles: IRole[] | { reference: string }[],
    allowedRoles: string[],
) => {
    if (allowedRoles.length === 0) {
        return true;
    }

    if (userRoles.length === 0) {
        return false;
    }

    return userRoles.some((role) => allowedRoles.includes(role.reference));
};

const RolesGate: React.FC<Props> = ({
    allowedRoles,
    renderNoAccess,
    userRoles,
    noAccessProps,
    children,
}: Props) => {
    const { user } = useAuth();
    const roles: IRole[] = user?.roles;

    const permissionGranted = hasRole(
        userRoles ? userRoles : roles ?? [],
        allowedRoles,
    );

    if (!permissionGranted && renderNoAccess) {
        return <>{renderNoAccess}</>;
    }

    if (!permissionGranted && !renderNoAccess && noAccessProps) {
        return cloneElement(children as any, { ...noAccessProps });
    }

    return permissionGranted ? <>{children}</> : <> </>;
};

export default RolesGate;
