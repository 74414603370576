import { Role } from './role';
import { CRM_URL, LIBRARIES_URL, PORTAL_URL } from '../config/urls';
import Project from '../shared/interfaces/project.interface';

const projects: Project[] = [
    {
        name: 'Portal',
        icon: 'fas fa-solar-panel',
        piIcon: 'FaSolarPanel',
        url: PORTAL_URL,
        roles: [
            Role.Administrator,
            Role.Commercial,
            Role.CommercialSupervisor,
            Role.CustomerSuccess,
            Role.Financial,
            Role.Integrator,
            Role.Logistics,
            Role.PointsAdministrator,
            Role.TechnicalSupport,
            Role.Production,
        ],
    },
    {
        name: 'Gestão',
        icon: 'fas fa-cog',
        piIcon: 'PiGear',
        url: CRM_URL,
        roles: [
            Role.Administrator,
            Role.Commercial,
            Role.CommercialSupervisor,
            Role.CustomerSuccess,
            Role.Financial,
            Role.Integrator,
            Role.Logistics,
            Role.PointsAdministrator,
            Role.TechnicalSupport,
            Role.Production,
        ],
    },
    {
        name: 'Biblioteca',
        icon: 'fas fa-book',
        piIcon: 'PiBooks',
        url: LIBRARIES_URL,
        roles: [],
    },
];

export default projects;
