import {
    Col,
    Image,
    Nav,
    Navbar,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap';
import { PiList } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { DashboardRoutePath } from '../../../routes/config';
import apps from '../../../assets/images/icons/apps.svg';

import Responsable from './Responsable';
import UserDropdownMenu from './UserDropdownMenu';
import {
    Icon,
    IconContainer,
    OptionContainer,
    OptionText,
    StyledAlignDiv,
    StyledButton,
    StyledNavbar,
    StyledNavbarBrand,
    StyledNavbarItemDivider,
} from './styles';
import useAllowedProjects from '../../../hooks/useAllowedProjects';
import useAllowedPages from '../../../hooks/useAllowedPages';
import NotificationsDropdown from './NotificationsDropdownMenu';

interface NavbarHeaderProps {
    isSmallScreen: boolean;
    handleToggle: () => void;
}

export default function NavBarHeader({
    isSmallScreen,
    handleToggle,
}: NavbarHeaderProps) {
    const decodedPathname = decodeURIComponent(window.location.pathname);

    const allowedProjects = useAllowedProjects();

    const allowedPages = useAllowedPages();

    if (isSmallScreen) {
        return (
            <StyledNavbar
                bg="dark"
                variant="dark"
                className="d-flex justify-content-between"
            >
                <StyledAlignDiv>
                    <StyledButton onClick={handleToggle}>
                        <PiList size={30} />
                    </StyledButton>
                </StyledAlignDiv>

                <StyledNavbarBrand
                    as={Link}
                    to={DashboardRoutePath}
                    className="ml-2"
                >
                    nexen
                </StyledNavbarBrand>

                <StyledAlignDiv />
            </StyledNavbar>
        );
    }

    return (
        <StyledNavbar expand="lg" bg="dark" variant="dark">
            <Navbar.Brand
                as={Link}
                to={DashboardRoutePath}
                style={{
                    fontSize: '28px',
                    fontWeight: 700,
                    width: '200px',
                    fontFamily: 'Branding',
                }}
                className="ml-2 d-block"
            >
                nexen
            </Navbar.Brand>

            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    {allowedPages.map(({ path, icon, text }, index: number) => (
                        <Nav.Link
                            key={index}
                            as={Link}
                            to={path}
                            className="text-center"
                            style={{
                                paddingInline: '1.5rem',
                                color: 'white',
                                backgroundColor:
                                    decodedPathname === path
                                        ? 'rgba(255, 255, 255, 0.18)'
                                        : undefined,
                            }}
                        >
                            <Image style={{ height: '24px' }} src={icon} />
                            <span className="d-block mt-1">{text}</span>
                        </Nav.Link>
                    ))}
                </Nav>

                <Responsable />

                <Nav className="pa-0 ma-0">
                    <NotificationsDropdown />

                    <StyledNavbarItemDivider className="ml-3 mr-3" />

                    <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose
                        overlay={
                            <Popover
                                id="popover-contained"
                                className="p-3 w-300"
                                style={{ maxWidth: '420px' }}
                            >
                                <Col>
                                    <Row>
                                        {allowedProjects.map(
                                            (
                                                { url, icon, name },
                                                index: number,
                                            ) => (
                                                <OptionContainer
                                                    key={index}
                                                    href={url}
                                                >
                                                    <IconContainer>
                                                        <Icon
                                                            className={icon}
                                                        />
                                                    </IconContainer>
                                                    <OptionText>
                                                        {name}
                                                    </OptionText>
                                                </OptionContainer>
                                            ),
                                        )}
                                    </Row>
                                </Col>
                            </Popover>
                        }
                    >
                        <Image
                            style={{ cursor: 'pointer' }}
                            src={apps}
                            width={30}
                            className="mr-2 ml-2"
                        />
                    </OverlayTrigger>

                    <UserDropdownMenu />
                </Nav>
            </Navbar.Collapse>
        </StyledNavbar>
    );
}
