/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
import { API_URL } from '../../config/urls';
import useRequest from './request';

export function useInvoiceHttpService() {
    const { get, post, patch, del } = useRequest({ baseURL: API_URL });

    const URI = '/api/invoices';

    const index = async (data: any) => {
        return await get(`${URI}`, data);
    };

    const indexUpdates = async (data: any) => {
        return await get(`${URI}/${data.invoiceId}/updates`, data);
    };

    const update = async (invoiceId: number, invoice: any) => {
        return await patch(`${URI}/${invoiceId}`, invoice);
    };

    const updatePortal = async (invoiceId: number) => {
        return await post(`${URI}/${invoiceId}/update`);
    };

    const updateInvoice = async (invoiceId: number, invoice: any) => {
        return await post(`${URI}/${invoiceId}/updateInvoice`, invoice);
    };

    const uploadReceipt = async (data: any, invoiceId: number) => {
        return await post(`${URI}/${invoiceId}/receipt`, data);
    };

    const deleteReceipt = async (invoiceId: number) => {
        return await del(`${URI}/${invoiceId}/receipt`);
    };

    const getSellers = async () => {
        return await get(`${URI}/sellers`);
    };

    return {
        index,
        indexUpdates,
        update,
        updatePortal,
        updateInvoice,
        uploadReceipt,
        deleteReceipt,
        getSellers,
    };
}
