import React from 'react';
import { Switch } from 'react-router-dom';
import { List } from '../../../pages/Invoices';
import PrivateRoute from '../../components/PrivateRoute';

export const InvoicesRoutePath = '/pedidos';

export const InvoicesRoutesComponent: React.FC = () => (
    <Switch>
        <PrivateRoute path={InvoicesRoutePath} component={List} exact />
    </Switch>
);
