/* eslint-disable import/prefer-default-export */
export enum Status {
    Started = 'DELIVERY_START',
    InvoiceDelivered = 'INVOICE_DELIVERED',
    InvoiceAtAffiliate = 'DELIVERY_AT_AFFILIATE',
    InvoiceEnRoute = 'DELIVERY_EN_ROUTE',
    InvoiceApproved = 'INVOICE_APPROVED',
    InvoicePayedErp = 'INVOICE_PAYED_ERP',
    InvoiceBilled = 'INVOICE_BILLED',
    InvoiceDispatched = 'INVOICE_DISPATCHED',
    InvoiceAnalysis = 'INVOICE_ANALYSIS',
}
