/* eslint-disable consistent-return */
export default function formatDateWithTime(value: string) {
    if (!value) {
        return;
    }

    const [yyyy, mm, dd, hh, mi] = value.split(/[/:\-T]/);

    return `${dd}/${mm}/${yyyy} ${hh}:${mi}`;
}
