/* eslint-disable react/require-default-props */
import { useEffect, useState } from 'react';
import { StyledLinkSeller, StyledH6, StyledDiv } from './styles';
import { List } from './List';

interface Props {
    hidden?: boolean;
    sidebar?: boolean;
}

const Responsable = (props: Props) => {
    const { hidden, sidebar } = props;
    const [showModal, setShowModal] = useState(false);

    const selectedBranch =
        localStorage.branch && JSON.parse(localStorage.branch);

    useEffect(() => {
        if (!selectedBranch) {
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!hidden && !sidebar && (
                <div className="mr-4 mt-n1" style={{ maxWidth: '180px' }}>
                    <StyledLinkSeller
                        title={selectedBranch && selectedBranch.name}
                        className="text-truncate"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        {selectedBranch
                            ? selectedBranch.name
                            : 'Selecione uma filial...'}
                    </StyledLinkSeller>
                    <StyledH6>Filial</StyledH6>
                </div>
            )}

            {sidebar && (
                <>
                    <StyledDiv onClick={() => setShowModal(true)}>
                        <p>
                            {selectedBranch
                                ? selectedBranch.name
                                : 'Selecione uma filial...'}
                        </p>
                        <p>Filial</p>
                    </StyledDiv>
                    <hr />
                </>
            )}

            <List
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
            />
        </>
    );
};

export default Responsable;
