import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    margin-top: 1rem;

    @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledCard = styled.div`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-radius: 5px !important;
    :hover {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #f2994a !important;
    }
    width: 100%;
`;

export const StyledCardBody = styled(Card.Body)``;

export const StyledCardText = styled(Card.Text)`
    font-size: 10px !important;
`;
