import React, { useState } from 'react';
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
    StyledPageSubTitle,
    StyledPageTitle,
} from '../../utils/theme/pageTitle';
import { ReactComponent as EraserIcon } from '../../assets/images/icons/eraser-solid.svg';
import { ReactComponent as ReactFilterIcon } from '../../assets/images/icons/filter.svg';
import BaseLayout from '../../components/BaseLayout';
import { ProductionTable } from './components/ProductionTable';
import { ProductionFilter } from './components/Filters';
import { useProduction } from '../../contexts/productionContext';
import { ProductionUpdateRoutePath } from '../../routes/config';
import { useAuth } from '../../hooks/use-auth';
import userHasRoles from '../../utils/userHasRoles';
import { Role } from '../../constants/role';

export default function List() {
    const { reset, formState } = useProduction();

    const [toggleFilter, setToggleFilter] = useState(false);

    const hasFilterSelected = () => formState.isDirty;

    const clearFilters = () => {
        reset();
        setToggleFilter(false);
    };

    const history = useHistory();

    const { user } = useAuth();

    const userCanBatchUpdate = userHasRoles(user, [
        Role.Administrator,
        Role.Production,
    ]);

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">Produção</StyledPageTitle>
                    <StyledPageSubTitle>
                        Tabela agregadora de dados relacionados a produção de
                        paineis
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <ButtonGroup>
                        {hasFilterSelected() && (
                            <Button
                                style={{
                                    color: '#2F80ED',
                                }}
                                className="mr-2"
                                variant="text"
                                onClick={clearFilters}
                            >
                                <EraserIcon
                                    fill="#2F80ED"
                                    className="mr-2"
                                    width={18}
                                />{' '}
                                Limpar filtros
                            </Button>
                        )}
                        <Button
                            className="mr-1"
                            variant="light"
                            style={{ backgroundColor: '#EEEEEE' }}
                            onClick={() => setToggleFilter(!toggleFilter)}
                        >
                            <ReactFilterIcon fill="#BDBDBD" width="16" />
                        </Button>
                        {userCanBatchUpdate && (
                            <Button
                                className="ml-1"
                                onClick={() =>
                                    history.push(ProductionUpdateRoutePath)
                                }
                            >
                                Atualização em lote
                            </Button>
                        )}
                    </ButtonGroup>
                </Col>
            </Row>

            <ProductionFilter toggleFilter={toggleFilter} />

            <ProductionTable />
        </BaseLayout>
    );
}
