/* eslint-disable import/prefer-default-export */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Controller } from 'react-hook-form';

import { Col, Form, InputGroup, Row } from 'react-bootstrap';

import { useCompany } from '../../../../contexts/companyContext';

const CompanyFilter: React.FC = () => {
    const { control } = useCompany();

    return (
        <>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Row className="mt-4">
                    <Col className="ml-2 mr-2">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <i className="fas fa-search"></i>
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <Controller
                                as={
                                    <Form.Control placeholder="Digite aqui o que procura..." />
                                }
                                type="text"
                                name="term"
                                control={control}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export { CompanyFilter };
