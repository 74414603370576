/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState } from 'react';
import { Row, Button, ButtonGroup, Col } from 'react-bootstrap';
import {
    StyledPageSubTitle,
    StyledPageTitle,
} from '../../utils/theme/pageTitle';

import { useInvoice } from '../../contexts/invoiceContext';

import BaseLayout from '../../components/BaseLayout';
import { InvoiceFilter } from './components/Filters';
import { InvoiceTable } from './components/InvoiceTable';
import { ReactComponent as EraserIcon } from '../../assets/images/icons/eraser-solid.svg';
import { ReactComponent as ReactFilterIcon } from '../../assets/images/icons/filter.svg';
import InvoiceDetail from './components/InvoiceDetail';

const List: React.FC = () => {
    const { reset, formState, showDetailModal, setShowDetailModal } =
        useInvoice();

    const [toggleFilter, setToggleFilter] = useState(false);

    const hasFilterSelected = () => formState.isDirty;

    const clearFilters = () => {
        reset();
        setToggleFilter(false);
    };

    return (
        <BaseLayout>
            <InvoiceDetail
                show={showDetailModal}
                onHide={() => {
                    setShowDetailModal(false);
                }}
            />

            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">Pedidos</StyledPageTitle>
                    <StyledPageSubTitle>
                        Informações de rastreio de pedidos.
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <ButtonGroup>
                        {hasFilterSelected() && (
                            <Button
                                style={{
                                    color: '#2F80ED',
                                }}
                                className="mr-2"
                                variant={'text'}
                                onClick={clearFilters}
                            >
                                <EraserIcon
                                    fill={'#2F80ED'}
                                    className={'mr-2'}
                                    width={18}
                                />{' '}
                                Limpar filtros
                            </Button>
                        )}
                        <Button
                            className="mr-1"
                            variant={'light'}
                            style={{ backgroundColor: '#EEEEEE' }}
                            onClick={() => setToggleFilter(!toggleFilter)}
                        >
                            <ReactFilterIcon fill="#BDBDBD" width="16" />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>

            <InvoiceFilter toggleFilter={toggleFilter} />

            <InvoiceTable />
        </BaseLayout>
    );
};

export { List };
