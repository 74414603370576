/* eslint-disable import/prefer-default-export */
import { InvoiceStatus } from '../constants/invoiceStatus';

export function getInvoiceStatusText(status: string) {
    if (status === InvoiceStatus.Invoice) {
        return 'Pedido em análise';
    }

    if (status === InvoiceStatus.Processing) {
        return 'Processando';
    }

    if (status === InvoiceStatus.ProcessingCancellation) {
        return 'Processando cancelamento';
    }

    if (status === InvoiceStatus.InvoiceApproved) {
        return 'Pedido aprovado';
    }

    if (status === InvoiceStatus.Processing) {
        return 'Processando';
    }

    if (status === InvoiceStatus.InvoiceReproved) {
        return 'Pedido reprovado';
    }

    if (status === InvoiceStatus.InvoiceCanceled) {
        return 'Pedido cancelado';
    }

    if (status === InvoiceStatus.InvoicePaymentError) {
        return 'Erro no pagamento';
    }

    if (status === InvoiceStatus.InvoicePayed) {
        return 'Pagamento efetuado';
    }

    if (status === InvoiceStatus.InvoicePayedErp) {
        return 'Pagamento confirmado';
    }

    if (status === InvoiceStatus.InvoiceDispatched) {
        return 'Expedido';
    }

    if (status === InvoiceStatus.InvoiceDeliveryEnRoute) {
        return 'Em rota de entrega';
    }

    if (status === InvoiceStatus.InvoiceDeliveryInTransit) {
        return 'Em trânsito';
    }

    if (status === InvoiceStatus.InvoiceSeparate) {
        return 'Em separação';
    }

    if (status === InvoiceStatus.InvoiceBilled) {
        return 'Faturado';
    }

    if (status === InvoiceStatus.InvoiceBillingErp) {
        return 'Faturamento liberado';
    }

    if (status === InvoiceStatus.InvoiceDelivered) {
        return 'Entregue';
    }

    if (status === InvoiceStatus.ConfirmingPaymentErp) {
        return 'Confirmando Pagamento';
    }

    if (status === InvoiceStatus.ConfirmingBillingErp) {
        return 'Confirmando faturamento';
    }

    if (status === InvoiceStatus.InvoiceBilling) {
        return 'Liberando faturamento';
    }

    if (status === InvoiceStatus.FutureSale) {
        return 'Venda futura';
    }

    return '';
}
