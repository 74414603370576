import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
    DashboardRoutesComponent,
    DashboardRoutePath,
    NotFoundRoutesComponent,
    InvoicesRoutePath,
    InvoicesRoutesComponent,
    CompaniesRoutePath,
    CompaniesRoutesComponent,
    CompanyEditRoutePath,
    ProductionRoutePath,
    ProductionRoutesComponent,
} from './config';

const AppRoutes: React.FC = () => (
    <Switch>
        <Route path={DashboardRoutePath} component={DashboardRoutesComponent} />
        <Route path={InvoicesRoutePath} component={InvoicesRoutesComponent} />
        <Route
            path={ProductionRoutePath}
            component={ProductionRoutesComponent}
        />
        <Route path={CompaniesRoutePath} component={CompaniesRoutesComponent} />
        <Route
            path={CompanyEditRoutePath}
            component={CompaniesRoutesComponent}
        />
        <Route component={NotFoundRoutesComponent} />
    </Switch>
);

export default AppRoutes;
