/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal, Row, Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useInvoice } from '../../../../contexts/invoiceContext';
import { useInvoiceHttpService } from '../../../../services/http/invoice-http';
import { usePortalInvoiceHttpService } from '../../../../services/http/portal-invoice-http';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../utils/theme/react-select-config';

interface filters {
    option: any;
    estimatedDeliveryDate: any;
    estimatedDeliveryEndDate: any;
    deliveryDate: any;
}

const DeliveryStatusModal = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [isDeliveredDisabled, setIsDeliveredDisabled] = useState(false);
    const [isDeliveredDisabledNoInTransit, setIsDeliveredDisabledNoInTransit] =
        useState(false);

    const defaultOptions = [
        {
            value: 1,
            label: 'Em trânsito',
        },
        {
            value: 2,
            label: 'Entregue',
        },
    ];

    const { updateInvoice, uploadReceipt } = useInvoiceHttpService();
    const { patchInvoice } = usePortalInvoiceHttpService();

    const {
        selectedInvoice,
        setShowReceiptModal,
        hasUploadedReceipt,
        fileData,
    } = useInvoice();
    const {
        control: formControl,
        watch,
        reset,
        // formState,
    } = useForm<filters>({
        shouldUnregister: false,
        defaultValues: {
            option: null,
            estimatedDeliveryDate: '',
            estimatedDeliveryEndDate: '',
            deliveryDate: '',
        },
    });

    const fieldsData = watch();

    // Método que adiciona quantidade de horas para um Date
    function addHours(date: Date, hours: number) {
        const hoursInMilliseconds = hours * 60 * 60 * 1000;

        const newDateTimestampWithAdditionalHours =
            date.getTime() + hoursInMilliseconds;

        date.setTime(newDateTimestampWithAdditionalHours);

        return date;
    }

    async function invoiceUpdateTracking() {
        let success = false;
        setLoading(true);

        try {
            if (fieldsData.estimatedDeliveryDate) {
                selectedInvoice.estimatedDeliveryDate = new Date(
                    fieldsData.estimatedDeliveryDate,
                );

                /*
          Utiliza-se o método addHours para não dar conflitos de timezone na
          hora de salvar no banco
        */
                selectedInvoice.estimatedDeliveryDate = addHours(
                    selectedInvoice.estimatedDeliveryDate,
                    3,
                );

                selectedInvoice.estimatedDeliveryDate =
                    selectedInvoice.estimatedDeliveryDate.toISOString();

                selectedInvoice.ocurrenceDate = new Date();
                selectedInvoice.ocurrenceDate =
                    selectedInvoice.ocurrenceDate.toISOString();
                selectedInvoice.lastStatus = 'Em trânsito';
            }
            await patchInvoice(selectedInvoice);
            await updateInvoice(selectedInvoice.id, selectedInvoice);
            success = true;
            toast.success('Pedido atualizado.');
        } catch (error) {
            console.error(error);
            toast.error('Erro ao atualizar pedido.');
        }

        setLoading(false);
        props.onHide();

        if (success) {
            window.location.reload();
        }
    }

    async function invoiceCompleteTracking() {
        let success = false;
        setLoading(true);

        try {
            if (fieldsData.deliveryDate) {
                selectedInvoice.ocurrenceDate = new Date(
                    fieldsData.deliveryDate,
                );
                selectedInvoice.ocurrenceDate = addHours(
                    selectedInvoice.ocurrenceDate,
                    23,
                );
                selectedInvoice.ocurrenceDate.setMinutes(59);
                selectedInvoice.ocurrenceDate.setSeconds(59);
                selectedInvoice.ocurrenceDate =
                    selectedInvoice.ocurrenceDate.toISOString();
                selectedInvoice.lastStatus = 'Entregue';
            }
            selectedInvoice.invoiceId = selectedInvoice.portalId;
            await uploadReceipt(fileData, selectedInvoice.id);
            await patchInvoice(selectedInvoice);
            await updateInvoice(selectedInvoice.id, selectedInvoice);
            success = true;
            toast.success('Pedido atualizado.');
        } catch (error) {
            toast.error('Erro ao atualizar pedido.');
        }

        setLoading(false);
        props.onHide();

        if (success) {
            window.location.reload();
        }
    }

    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInvoice.portalId]);

    useEffect(() => {
        if (selectedInvoice) {
            if (
                selectedInvoice?.invoiceUpdates?.find(
                    (update: any) => update.description === 'Entregue',
                )
            ) {
                setIsDeliveredDisabled(true);
            } else {
                setIsDeliveredDisabled(false);
            }

            if (
                selectedInvoice?.invoiceUpdates?.find(
                    (update: any) => update.description === 'Em trânsito',
                )
            ) {
                setIsDeliveredDisabledNoInTransit(false);
            } else {
                setIsDeliveredDisabledNoInTransit(true);
            }
        }
    }, [selectedInvoice, props]);

    return (
        <React.Fragment>
            <Modal
                className={'modal-dialog-scrollable'}
                {...props}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Status de Entrega
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'w-100'}>
                    <Controller
                        isClearable={false}
                        className="form-control-nexen"
                        as={Select}
                        control={formControl}
                        name="option"
                        options={defaultOptions}
                        styles={{
                            control: tradeControl,
                            option: optionsTheme,
                        }}
                        theme={theme}
                        placeholder="Selecione uma opção"
                        defaultValue={null}
                    />

                    <Row
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 350,
                        }}
                    >
                        {fieldsData?.option?.value === 1 && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                }}
                            >
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Form.Group
                                        style={{ width: '100%' }}
                                        controlId="estimatedDeliveryDate"
                                    >
                                        <Form.Label
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: 10,
                                            }}
                                        >
                                            Previsão de entrega
                                        </Form.Label>
                                        <Controller
                                            control={formControl}
                                            name="estimatedDeliveryDate"
                                            render={({ onChange, value }) => (
                                                <Form.Control
                                                    value={value}
                                                    onChange={onChange}
                                                    name="estimatedDeliveryDate"
                                                    type="date"
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Button
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={invoiceUpdateTracking}
                                        className={'w-100'}
                                    >
                                        {loading ? (
                                            <Spinner animation={'border'} />
                                        ) : (
                                            <span>Salvar</span>
                                        )}
                                    </Button>
                                </Row>
                            </div>
                        )}
                        {fieldsData?.option?.value === 2 && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                }}
                            >
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Form.Group
                                        style={{ width: '100%' }}
                                        controlId="deliveryDate"
                                    >
                                        <Form.Label
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginTop: 10,
                                            }}
                                        >
                                            Data de entrega
                                        </Form.Label>

                                        <Controller
                                            control={formControl}
                                            name="deliveryDate"
                                            render={({ onChange, value }) => (
                                                <Form.Control
                                                    value={value}
                                                    onChange={onChange}
                                                    name="deliveryDate"
                                                    type="date"
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Row>

                                {!selectedInvoice?.deliveryReceiptFormat && (
                                    <Row
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Button
                                            className={'mb-3 w-100'}
                                            variant={'secondary'}
                                            onClick={() => {
                                                if (
                                                    !selectedInvoice?.deliveryReceiptFormat
                                                ) {
                                                    setShowReceiptModal(true);
                                                }
                                            }}
                                        >
                                            Anexar
                                        </Button>
                                    </Row>
                                )}

                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Button
                                        disabled={
                                            isDeliveredDisabled ||
                                            isDeliveredDisabledNoInTransit ||
                                            !hasUploadedReceipt
                                        }
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={invoiceCompleteTracking}
                                        className={'w-100'}
                                    >
                                        {loading ? (
                                            <Spinner animation={'border'} />
                                        ) : (
                                            <span>Salvar</span>
                                        )}
                                    </Button>
                                    {isDeliveredDisabled && (
                                        <span
                                            style={{
                                                display: 'flex',
                                                marginTop: 10,
                                            }}
                                        >
                                            Já foi lançado um status
                                            anteriormente
                                        </span>
                                    )}
                                    {isDeliveredDisabledNoInTransit && (
                                        <span
                                            style={{
                                                display: 'flex',
                                                marginTop: 10,
                                            }}
                                        >
                                            {`É preciso antes lançar um status de "Em trânsito"`}
                                        </span>
                                    )}
                                    {!isDeliveredDisabledNoInTransit &&
                                        !hasUploadedReceipt && (
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    marginTop: 10,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                É preciso anexar um comprovante
                                                de entrega antes de salvar
                                            </span>
                                        )}
                                </Row>
                            </div>
                        )}
                    </Row>
                </Modal.Body>
                <Modal.Footer className={'ml-3 mr-3 mb-2'}></Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default DeliveryStatusModal;
