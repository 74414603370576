import { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { InputActionMeta, MultiValue, SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import Select, { SelectOption } from '../../../../components/Select';
import {
    BatchUpdateOptions,
    BatchUpdateTypesEnum,
} from '../../../../constants/batchUpdateTypes';
import useConciseProductionInvoices from '../../../../hooks/useConciseProductionInvoices';
import { ProductionRoutePath } from '../../../../routes/config/Production';
import usePortalHttpService from '../../../../services/http/portal-http';
import DeliveryDateForm from '../DeliveryDateForm';
import StyledButtonGroup from './styles';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { NotificationIcon } from '../../../../constants/notificationIcon';
import useDelayedState from '../../../../hooks/useDelayedState';

export interface BatchUpdateFormFields {
    updateType: SingleValue<SelectOption<BatchUpdateTypesEnum>>;
    invoices: MultiValue<SelectOption<number>>;
    deliveryDate: Date | null;
}

const minSearchLength = 3;

export default function BatchUpdateForm() {
    const history = useHistory();

    const batchUpdateForm = useForm<BatchUpdateFormFields>({
        defaultValues: {
            invoices: [],
            updateType: null,
            deliveryDate: null,
        },
    });

    const batchUpdateFormData = batchUpdateForm.watch();

    const { batchUpdateInvoices } = usePortalHttpService();

    const batchUpdateMutation = useMutation({
        mutationFn: async (data: {
            updateType: BatchUpdateTypesEnum;
            invoiceIds: number[];
            deliveryDate?: Date;
        }) => {
            await batchUpdateInvoices(data);
        },
        onError: (error) => {
            if (error && typeof error === 'object' && 'message' in error) {
                toast.error((error as { message: string }).message, {
                    theme: 'colored',
                });
            } else {
                toast.error('Ocorreu um erro ao atualizar os pedidos', {
                    theme: 'colored',
                });
            }
        },
        onSuccess: () => {
            toast.success('Pedidos atualizados com sucesso', {
                theme: 'colored',
            });
            batchUpdateForm.reset();
        },
    });

    const [showConfirmation, setShowConfirmation] = useState(false);

    const onSubmit: SubmitHandler<BatchUpdateFormFields> = (formData) => {
        if (!formData.updateType || !formData.invoices.length) {
            return;
        }

        const data: {
            updateType: BatchUpdateTypesEnum;
            invoiceIds: number[];
            deliveryDate?: Date;
        } = {
            updateType: formData.updateType?.value,
            invoiceIds: formData.invoices.map((invoice) => invoice.value),
        };

        if (formData.updateType.value === BatchUpdateTypesEnum.DeliveryDate) {
            if (!formData.deliveryDate) {
                return;
            }
            data.deliveryDate = formData.deliveryDate;
        }

        batchUpdateMutation.mutate(data);
    };

    const onConfirm = () => {
        batchUpdateForm.handleSubmit(onSubmit)();
        setShowConfirmation(false);
    };

    const [searchTerm, setSearchTerm] = useDelayedState('');
    const [inputText, setInputText] = useState('');

    const { invoices, isLoading } = useConciseProductionInvoices(searchTerm);

    const handleInputChange = (
        newValue: string,
        actionMeta: InputActionMeta,
    ) => {
        if (
            actionMeta.action !== 'input-blur' &&
            actionMeta.action !== 'menu-close'
        ) {
            setInputText(newValue);
            setSearchTerm(newValue);
        }
    };

    useEffect(() => {
        const unblock = history.block(
            batchUpdateFormData.invoices.length
                ? 'Deseja realmente deixar a página? Os dados inseridos serão perdidos'
                : true,
        );

        return () => unblock();
    }, [history, batchUpdateFormData.invoices.length]);

    return (
        <>
            <Form
                className="pl-2 pr-2 mt-3"
                onSubmit={batchUpdateForm.handleSubmit(() =>
                    setShowConfirmation(true),
                )}
            >
                <Form.Group className="mb-3" controlId="formUpdateType">
                    <Form.Label>Tipo de atualização</Form.Label>
                    <Controller
                        control={batchUpdateForm.control}
                        name="updateType"
                        render={({ onChange, value }) => (
                            <Select
                                options={BatchUpdateOptions}
                                onChange={onChange}
                                value={value}
                                isInvalid={Boolean(
                                    batchUpdateForm.errors.updateType,
                                )}
                                isDisabled={batchUpdateMutation.isLoading}
                            />
                        )}
                        rules={{ required: true }}
                    />

                    {batchUpdateForm.errors.updateType && (
                        <Form.Text className="text-danger">
                            Campo obrigatório
                        </Form.Text>
                    )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formInvoices">
                    <Form.Label>Pedidos</Form.Label>
                    <Controller
                        control={batchUpdateForm.control}
                        name="invoices"
                        render={({ onChange, value }) => (
                            <Select
                                options={invoices}
                                isLoading={isLoading}
                                onChange={onChange}
                                value={value}
                                inputValue={inputText}
                                isMulti
                                onInputChange={handleInputChange}
                                isInvalid={Boolean(
                                    batchUpdateForm.errors.invoices,
                                )}
                                noOptionsMessage={
                                    searchTerm.length < minSearchLength
                                        ? `Digite ao menos ${minSearchLength} caracteres para iniciar a busca`
                                        : undefined
                                }
                                isDisabled={batchUpdateMutation.isLoading}
                            />
                        )}
                        rules={{
                            validate: (selectedInvoices) =>
                                Boolean(selectedInvoices.length),
                        }}
                    />

                    {batchUpdateForm.errors.invoices && (
                        <Form.Text className="text-danger">
                            Campo obrigatório
                        </Form.Text>
                    )}
                </Form.Group>

                {batchUpdateFormData.updateType?.value ===
                    BatchUpdateTypesEnum.DeliveryDate && (
                    <DeliveryDateForm
                        control={batchUpdateForm.control}
                        errors={batchUpdateForm.errors}
                        disabled={batchUpdateMutation.isLoading}
                    />
                )}

                <StyledButtonGroup className="float-right">
                    <Button
                        variant="light"
                        style={{ backgroundColor: '#eee' }}
                        onClick={() => history.push(ProductionRoutePath)}
                        disabled={batchUpdateMutation.isLoading}
                    >
                        Voltar
                    </Button>
                    <Button
                        className="ml-1"
                        variant="primary"
                        type="submit"
                        disabled={batchUpdateMutation.isLoading}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {batchUpdateMutation.isLoading ? (
                            <>
                                <Spinner
                                    animation="border"
                                    size="sm"
                                    style={{ marginRight: '10px' }}
                                />
                                <span>Atualizando...</span>
                            </>
                        ) : (
                            'Atualizar'
                        )}
                    </Button>
                </StyledButtonGroup>
            </Form>

            {showConfirmation && (
                <ConfirmationDialog
                    show={showConfirmation}
                    onHide={() => setShowConfirmation(false)}
                    onConfirm={onConfirm}
                    title="Atualizar pedidos"
                    text="Deseja realmente atualizar os pedidos?"
                    icon={NotificationIcon.Info}
                />
            )}
        </>
    );
}
