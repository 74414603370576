import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery, useMutation } from 'react-query';
import Highlighter from 'react-highlight-words';
import { Button, Col, Row, Dropdown, Spinner, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { StyledTh } from './styles';
import { ReactComponent as More } from '../../../../assets/images/icons/more.svg';
import { ReactComponent as List } from '../../../../assets/images/icons/list.svg';
import { ReactComponent as Eraser } from '../../../../assets/images/icons/eraser-solid.svg';
import { useCompanyHttpService } from '../../../../services/http/company-http';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import formatDate from '../../../../utils/localization/formatDate';
import { rowsPerPage } from '../../../../constants/pagination';
import { useCompany } from '../../../../contexts/companyContext';
import { CompanyEditRoutePath } from '../../../../routes/config';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import confirmDialog from '../../../../utils/dialog/confirmDialog';

const CompanyTable = () => {
    const history = useHistory();

    const { formData: filter, setSelectedCompany, reset } = useCompany();

    const { index, remove } = useCompanyHttpService();

    const loadMoreRef = useRef();

    const loadCompanies = async ({ pageParam = 0 }: any) => {
        const params = {
            term: filter.term ? filter.term : '',
            skip: pageParam,
            take: rowsPerPage,
        };

        const response: any = await index(params);

        return {
            data: response.data.data,
            nextPage: pageParam,
        };
    };

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useInfiniteQuery('companies', loadCompanies, {
            getNextPageParam: (lastPage) => lastPage.nextPage + 1,
        });

    const mutationDelete = useMutation(
        async (id: number) => {
            await remove({
                companyId: id,
            });
        },
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao excluir a Transportadora.');
            },
            onSuccess: () => {
                toast.success('Transportadora excluída com sucesso!');
            },
            onSettled: () => {
                refetch();
            },
        },
    );

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 500);

        return () => clearTimeout(timer);
    }, [filter, refetch, reset]);

    return (
        <>
            <Row>
                <Col className="ml-2 mr-2">
                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh>Nº DA TRANSPORTADORA</StyledTh>
                                <StyledTh>NOME</StyledTh>
                                <StyledTh>CNPJ</StyledTh>
                                <StyledTh>ULTIMA ATUALIZAÇÃO</StyledTh>
                            </tr>
                        </thead>

                        <tbody>
                            {data?.pages &&
                                data?.pages.map((group: any) =>
                                    group?.data.map(
                                        (item: any, index2: any) => (
                                            <tr key={index2}>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={item?.id.toString()}
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={item?.name.toString()}
                                                    />
                                                </td>
                                                <td>
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            filter.term,
                                                        ]}
                                                        textToHighlight={item?.cnpj.replace(
                                                            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                                            '$1.$2.$3/$4-$5',
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    {formatDate(
                                                        item?.updatedAt,
                                                    )}
                                                </td>

                                                <td className="d-flex">
                                                    {}
                                                    <Dropdown key="left">
                                                        <Dropdown.Toggle
                                                            bsPrefix="nexen"
                                                            as={Button}
                                                            variant="text"
                                                        >
                                                            <More
                                                                width="10"
                                                                height="20"
                                                            />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setSelectedCompany(
                                                                        item,
                                                                    );
                                                                    history.push(
                                                                        CompanyEditRoutePath,
                                                                    );
                                                                }}
                                                            >
                                                                <List
                                                                    width="18"
                                                                    height="18"
                                                                    className="ml-n3 mr-2"
                                                                />
                                                                Detalhes
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={async () => {
                                                                    const confirm: any =
                                                                        await confirmDialog(
                                                                            'Excluir Transportadora',
                                                                            'Deseja excluir a Transportadora selecionada?',
                                                                            'info',
                                                                        );

                                                                    if (
                                                                        !confirm
                                                                    ) {
                                                                        return;
                                                                    }

                                                                    mutationDelete.mutateAsync(
                                                                        item?.id,
                                                                    );
                                                                }}
                                                            >
                                                                <Eraser
                                                                    width="18"
                                                                    height="18"
                                                                    className="ml-n3 mr-2"
                                                                />
                                                                Excluir
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ),
                                    ),
                                )}
                        </tbody>
                    </Table>
                </Col>

                <Col md={12} className="text-center">
                    {isFetchingNextPage ? (
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    ) : (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default CompanyTable;
