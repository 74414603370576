/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Nav, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import BaseLayout from '../../components/BaseLayout';
import { CompaniesRoutePath } from '../../routes/config';
import {
    StyledPageSubTitle,
    StyledPageTitle,
} from '../../utils/theme/pageTitle';
import CompanyData from './components/CompanyData';
import { StyledH4, StyledLink, StyledNav } from './styles';
import { useCompany } from '../../contexts/companyContext';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { useCompanyHttpService } from '../../services/http/company-http';
import { StatusTable } from './components/StatusTable';
import { IntegrationTable } from './components/IntegrationTable';
import StatusDetail from './components/StatusDetail';
import IntegrationDetail from './components/IntegrationDetail';
import { CompanyFilter } from './components/Filters';

interface CompanyForm {
    id?: number;
    name: string;
    cnpj: string;
    protheusId: string;
}

const Edit: React.FC = () => {
    const history = useHistory();
    const [tab, setTab] = useState('company');
    const {
        selectedCompany,
        setShowStatusDetailModal,
        setShowIntegrationDetailModal,
        showStatusDetailModal,
        showIntegrationDetailModal,
        setSelectedStatus,
        setSelectedIntegration,
    } = useCompany();
    const { save } = useCompanyHttpService();
    const methods = useForm<CompanyForm>({
        defaultValues: {
            name: '',
            cnpj: '',
            protheusId: '',
        },
    });

    const mutationSave = useMutation(
        async (values: CompanyForm) => {
            await save({
                id: selectedCompany?.id,
                name: values.name,
                cnpj: values.cnpj,
                protheusId: values.protheusId,
            });
        },
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao salvar a Transportadora.');
            },
            onSuccess: () => {
                toast.success('Transportadora salva com sucesso!');
                history.push(CompaniesRoutePath);
            },
        },
    );

    async function saveCompany() {
        const values = methods.getValues();

        if (values.name === '' || !values.cnpj) {
            toast.error('Preencha os campos corretamente.');
            return;
        }

        if (values.cnpj.replace(/\D/g, '').length < 14) {
            toast.error('CNPJ deve ter 14 dígitos');
            return;
        }

        if (
            selectedCompany.id &&
            values.name === selectedCompany.name &&
            values.cnpj === selectedCompany.cnpj &&
            values.protheusId === selectedCompany.protheusId
        ) {
            toast.warn('Nenhuma alteração realizada.');
            return;
        }

        const company: CompanyForm = {
            id: selectedCompany.id,
            name: values.name,
            cnpj: values?.cnpj?.replace(/\D/g, ''),
            protheusId: values.protheusId,
        };

        mutationSave.mutateAsync(company);
    }

    useEffect(() => {
        if (selectedCompany.name) {
            methods.setValue('protheusId', selectedCompany.protheusId);
            methods.setValue('name', selectedCompany.name);
            methods.setValue(
                'cnpj',
                selectedCompany.cnpj.replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4-$5',
                ),
            );
        }
    }, [methods, selectedCompany]);

    const handleButtonClick = () => {
        if (tab === 'company') {
            saveCompany();
        } else if (tab === 'statuses') {
            setShowStatusDetailModal(true);
        } else {
            setShowIntegrationDetailModal(true);
        }
    };

    const getButtonText = () => {
        if (tab === 'company') {
            return 'Salvar';
        }
        if (tab === 'statuses') {
            return 'Novo Status';
        }
        return 'Nova Integração';
    };

    return (
        <BaseLayout>
            <StatusDetail
                show={showStatusDetailModal}
                onHide={() => {
                    setShowStatusDetailModal(false);
                    setSelectedStatus({});
                }}
            />
            <IntegrationDetail
                show={showIntegrationDetailModal}
                onHide={() => {
                    setShowIntegrationDetailModal(false);
                    setSelectedIntegration({});
                }}
            />
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Transportadoras
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Cadastro e gerenciamento de transportadoras parceiras
                    </StyledPageSubTitle>
                </Col>
                <Col className="text-right">
                    <Button
                        className="float-right w-25"
                        onClick={handleButtonClick}
                    >
                        {getButtonText()}
                    </Button>
                </Col>
            </Row>
            <Row className="pl-2 pr-2 mt-4">
                <Col>
                    <StyledH4>
                        <StyledLink to={CompaniesRoutePath}>
                            <i className="fas fa-chevron-left mr-2" />
                            Cadastro
                        </StyledLink>
                    </StyledH4>
                </Col>
            </Row>
            <Row className="pl-2 pr-2 mt-2">
                <Col>
                    <p>
                        {selectedCompany.id
                            ? 'Informações sobre a Transportadora, status de transporte e integrações'
                            : 'Informações sobre a Transportadora'}
                    </p>
                </Col>
            </Row>

            <FormProvider
                control={methods.control}
                handleSubmit={methods.handleSubmit}
                reset={methods.reset}
                setError={methods.setError}
                clearErrors={methods.clearErrors}
                setValue={methods.setValue}
                getValues={methods.getValues}
                trigger={methods.trigger}
                formState={methods.formState}
                watch={methods.watch}
                errors={methods.errors}
                register={methods.register}
                unregister={methods.unregister}
            >
                <Form.Group className="ml-2 mt-0" controlId="formConfiguration">
                    <Row className="mb-3 ">
                        <Col md={8}>
                            <StyledNav
                                variant="tabs"
                                sm={8}
                                activeKey={tab}
                                onSelect={(selectedKey: string) =>
                                    setTab(selectedKey)
                                }
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="company">
                                        Dados
                                    </Nav.Link>
                                </Nav.Item>
                                {selectedCompany.id && (
                                    <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="statuses">
                                                Status de Transporte
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="integrations">
                                                Chaves de Integração
                                            </Nav.Link>
                                        </Nav.Item>
                                    </>
                                )}
                            </StyledNav>
                        </Col>
                    </Row>

                    {tab !== 'company' && <CompanyFilter />}

                    {tab === 'company' && <CompanyData />}

                    {tab === 'statuses' && <StatusTable />}

                    {tab === 'integrations' && <IntegrationTable />}
                </Form.Group>
            </FormProvider>
        </BaseLayout>
    );
};

export default Edit;
