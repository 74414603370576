/* eslint-disable react/jsx-props-no-spreading */
import { useState, createContext, useContext } from 'react';
import { useForm } from 'react-hook-form';

const InvoiceContext = createContext<any>({});
const useInvoice = () => useContext(InvoiceContext);

interface filters {
    term: string;
    companies: [{ label: string; value: number }];
    redispatchCompanies: [{ label: string; value: number }];
    statuses: [{ label: string; value: number }];
    estimatedDeliveryDate: Date;
    estimatedDeliveryEndDate: Date;
    seller: [{ label: string; value: number }];
}

function InvoiceProvider(props: any) {
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [hasUploadedReceipt, setHasUploadedReceipt] = useState(false);
    const [showDeliveryStatusModal, setShowDeliveryStatusModal] =
        useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [fileData, setFileData] = useState();

    const { control, watch, reset, formState } = useForm<filters>({
        shouldUnregister: false,
        defaultValues: {
            term: '',
            companies: [],
            redispatchCompanies: [],
            statuses: [],
            seller: [],
        },
    });

    const formData = watch();

    return (
        <InvoiceContext.Provider
            value={{
                hasUploadedReceipt,
                setHasUploadedReceipt,
                showDeliveryStatusModal,
                setShowDeliveryStatusModal,
                showReceiptModal,
                setShowReceiptModal,
                showDetailModal,
                setShowDetailModal,
                selectedInvoice,
                setSelectedInvoice,
                control,
                watch,
                reset,
                formState,
                formData,
                fileData,
                setFileData,
            }}
            {...props}
        />
    );
}

export { InvoiceProvider, useInvoice };
