/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-const */
/* eslint-disable no-return-await */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation } from 'react-query';
import {
    Button,
    Col,
    Row,
    Dropdown,
    Spinner,
    Table,
    Nav,
} from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { StyledTdText, StyledTh, StyledTr } from './styles';
import { ReactComponent as Settings } from '../../../../assets/images/icons/settings.svg';
import { ReactComponent as Save } from '../../../../assets/images/icons/floppy-disk-save.svg';
import { ReactComponent as ReactFeedbackIcon } from '../../../../assets/images/icons/feedback.svg';
import { ReactComponent as ReactCancelIcon } from '../../../../assets/images/icons/cancel.svg';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/images/icons/more.svg';
import { ReactComponent as ReactTruckIcon } from '../../../../assets/images/icons/truck.svg';
import { ReactComponent as ReactPrintIcon } from '../../../../assets/images/icons/print.svg';
import { ReactComponent as ReactInvoiceAnalysis } from '../../../../assets/images/icons/analysis.svg';
import { ReactComponent as ReactTriangulationIcon } from '../../../../assets/images/icons/triangulation.svg';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import formatDate from '../../../../utils/localization/formatDate';
import { rowsPerPage } from '../../../../constants/pagination';
import { Status } from '../../../../constants/status';
import usePortalHttpService from '../../../../services/http/portal-http';
import { StyledNav } from '../../../Companies/styles';
import { formatCurrency } from '../../../../utils/text/strings';
import getDeliveryDate from '../../../../utils/text/getDeliveryDate';
import confirmDialog from '../../../../utils/dialog/confirmDialog';
import RolesGate from '../../../../components/RolesGate';
import { Role } from '../../../../constants/role';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../utils/theme/react-select-config';
import { useProduction } from '../../../../contexts/productionContext';
import ObservationModal from '../ObservationModal';
import { getInvoiceStatusText } from '../../../../utils/text/getInvoiceStatusText';
import { NFE_URL } from '../../../../config/urls';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import arrayBufferToBase64 from '../../../../utils/text/arrayBufferToBase64';
import { usePortalInvoiceHttpService } from '../../../../services/http/portal-invoice-http';
import { useInvoiceHttpService } from '../../../../services/http/invoice-http';
import { InvoiceTriangulationStatus } from '../../../../utils/constants/invoiceStatus';
import InvoiceTriangulationModal from '../InvoiceTriangulationModal';
import { useAuth } from '../../../../hooks/use-auth';
import userHasRoles from '../../../../utils/userHasRoles';

const ProductionTable = () => {
    const [hasChange, setHasChange] = useState(false);
    const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<any>();
    const [selectedEstimatedDeliveryDate, setSelectedEstimatedDeliveryDate] =
        useState<Date | null>(null);
    const [invoiceCount, setInvoiceCount] = useState(0);
    const [invoicesTotalValue, setInvoicesTotalValue] = useState(0);
    const [selectedDeliveryCompany, setSelectedDeliveryCompany] =
        useState<any>();
    const [selectedReDispatchCompany, setSelectedReDispatchCompany] =
        useState<any>();

    const {
        patchInvoice,
        indexProduction,
        getInvoicePdf,
        getNfeFutureSalePdf,
        getNfeSimpleShipmentPdf,
        getNfeTriangulation,
    } = usePortalHttpService();

    const { patchInvoice: updatePortalInvoice } = usePortalInvoiceHttpService();

    const { update } = useInvoiceHttpService();

    const {
        companyOptions,
        showObservationModal,
        setShowObservationModal,
        setInvoiceObservation,
        invoiceObservation,
        formData: filter,
        reset,
        moduleOptions,
        selectedInvoice,
        setSelectedInvoice,
        stateParam,
        invoiceTriangulation,
        setInvoiceTriangulation,
        showInvoiceTriangulationModal,
        setShowInvoiceTriangulationModal,
    } = useProduction();

    const loadMoreRef = useRef(null);

    const [tab, setTab] = useState(
        Status.InvoicePayedErp +
            ',' +
            Status.InvoiceBilled +
            ',' +
            Status.InvoiceDispatched,
    );

    const [month, setMonth] = useState<number>(DateTime.local().month);

    const loadInvoiceProduction = async ({ pageParam = 0 }: any) => {
        let params: any = {};

        const localStorageJson = JSON.parse(
            localStorage.getItem('branch') || '',
        );

        params = {
            skip: pageParam,
            take: rowsPerPage,
            erpTenantId:
                localStorageJson?.companyCode +
                ',' +
                localStorageJson.branchCode,
        };

        const hasNfFilter = filter.hasNf ? filter.hasNf.value : null;

        const companyFilter =
            filter.companies?.map((item: any) => item.value) ?? [];

        if (filter.term) {
            params.term = filter.term ?? '';
        } else {
            params.status = tab;

            if (tab === 'active') {
                params.month = month;
            }
        }

        if (filter.date) {
            params.date = new Date(filter.date).toISOString() ?? '';
        }

        if (companyFilter.length) {
            params.companies = companyFilter?.join(',') ?? '';
        }

        if (hasNfFilter) {
            params.hasNf = hasNfFilter;
        }

        if (stateParam) {
            if (stateParam.value !== 0) {
                params.state = stateParam.label;
            }
        }

        if (filter.city) {
            const cityFilter =
                filter.city && filter.city.map((item: any) => item.label);

            if (cityFilter.length > 0) {
                params.city = cityFilter?.join(',') ?? '';
            }
        }

        if (filter.observation) {
            params.observation = encodeURIComponent(filter.observation);
        }

        // Seta o ano selecionado ou ano atual
        if (filter.years) {
            params.years = filter.years.label;
        } else {
            params.years = new Date().getFullYear();
        }

        if (filter.modules) {
            const modulesFilter =
                filter.modules && filter.modules.map((item: any) => item.value);

            if (modulesFilter.length > 0) {
                params.modules = modulesFilter?.join(',') ?? '';
            }
        }

        const response: any = await indexProduction(params);

        if (
            response.data.meta?.total !== undefined &&
            response.data.meta?.total !== null
        ) {
            setInvoiceCount(response.data.meta.total);
        }

        if (
            response.data.meta?.totalInvoicesValue !== undefined &&
            response.data.meta?.totalInvoicesValue !== null
        ) {
            setInvoicesTotalValue(response.data.meta.totalInvoicesValue);
        }

        return {
            data: response.data.data,
            nextPage: pageParam,
        };
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch,
        isRefetching,
        remove,
    } = useInfiniteQuery('invoiceProduction', loadInvoiceProduction, {
        getNextPageParam: (lastPage) => lastPage.nextPage + 1,
        onSuccess: (data) => {
            if (
                filter.term &&
                data?.pages[0]?.data.length === 1 &&
                data?.pages[0]?.data[0]
            ) {
                const invoice = data?.pages[0]?.data[0];

                if (
                    invoice?.status === Status.InvoiceApproved ||
                    invoice?.status === Status.InvoiceAnalysis
                ) {
                    setTab(
                        Status.InvoiceApproved + ',' + Status.InvoiceAnalysis,
                    );
                }

                if (
                    invoice?.status === Status.InvoicePayedErp ||
                    invoice?.status === Status.InvoiceBilled ||
                    invoice?.status === Status.InvoiceDispatched
                ) {
                    setTab(
                        Status.InvoicePayedErp +
                            ',' +
                            Status.InvoiceBilled +
                            ',' +
                            Status.InvoiceDispatched,
                    );

                    setMonth(
                        DateTime.fromISO(invoice?.deliveryDate)
                            .toJSDate()
                            .getMonth() + 1,
                    );
                }

                if (invoice?.paymentMethod === 'FINANCING') {
                    setTab('financing');
                }

                if (invoice?.invoiceProduction?.productionActive) {
                    setTab('active');
                }

                if (invoice?.invoiceProduction?.productionPending) {
                    setTab('pending');

                    setTab('pending');
                }
            }
        },
    });

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    async function saveInvoice(invoice: any, action: string): Promise<void> {
        if (
            tab === 'active' &&
            invoice.nf &&
            invoice.deliveryDate !== selectedDeliveryDate
        ) {
            toast.error('Impossível trocar data de produção de pedido com NF');
            return;
        }

        invoice.portalId = invoice.id;
        invoice.estimatedDeliveryDate =
            selectedEstimatedDeliveryDate?.toISOString();
        invoice.deliveryDate = selectedDeliveryDate;
        invoice.logisticsCompanyId = selectedDeliveryCompany;
        invoice.carrierCode = selectedDeliveryCompany;
        invoice.reDispatchCompanyId = selectedReDispatchCompany;
        if (invoice.invoiceProduction) {
            invoice.invoiceProduction.productionActive =
                action === 'active' ? true : false;
            invoice.invoiceProduction.productionPending =
                action === 'pending' ? true : false;
            invoice.invoiceProduction.observation =
                invoiceObservation.observation;
        } else {
            invoice.invoiceProduction = {
                productionActive: action === 'active' ? true : false,
                productionPending: action === 'pending' ? true : false,
                observation: invoiceObservation.observation,
            };
        }

        try {
            const invoiceToUpdate = {
                id: invoice.id,
                reDispatchCompanyId: invoice.reDispatchCompanyId,
                companyProtheusCode: invoice.logisticsCompanyId,
            };
            await update(invoice.id, invoiceToUpdate);
            await patchInvoice(invoice);
            await updatePortalInvoice(invoice);
            toast.success('Pedido salvo com sucesso!');
            refetch();
        } catch (error: any) {
            toast.error(error.message);
        }
    }

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setSelectedInvoice({});
            setInvoiceObservation({});
            setSelectedDeliveryDate({});
            setSelectedEstimatedDeliveryDate(null);
            setSelectedDeliveryCompany({});
            setSelectedReDispatchCompany({});
            setHasChange(false);
            setInvoiceTriangulation(null);
        }
    }, []);

    const mutationPrint = useMutation(
        async (invoice: any) => await getInvoicePdf(invoice?.id),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao gerar o arquivo.');
            },
            onSettled: (data, error, variables) => {
                let a = document.createElement('a');

                a.href =
                    'data:application/pdf;base64,' +
                    arrayBufferToBase64(data?.data?.data);
                a.download = `Pedido #${variables.id}.pdf`;
                a.click();
            },
        },
    );

    const mutationGetNfeSimpleShipment = useMutation(
        async (id: number) => await getNfeSimpleShipmentPdf(id),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao gerar o arquivo.');
            },
            onSuccess: (response) => {
                let a = document.createElement('a');

                a.href = 'data:application/pdf;base64,' + response?.data?.data;
                a.download = 'NFe_Simples_Remessa.pdf';
                a.click();
            },
        },
    );

    const mutationGetNfeFutureSale = useMutation(
        async (id: number) => await getNfeFutureSalePdf(id),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao gerar o arquivo.');
            },
            onSuccess: (response) => {
                let a = document.createElement('a');

                a.href = 'data:application/pdf;base64,' + response?.data?.data;
                a.download = 'NFe_Venda_Futura.pdf';
                a.click();
            },
        },
    );

    const mutationGetNfeTriangulation = useMutation(
        async (id: number) => await getNfeTriangulation(id),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao gerar o arquivo.');
            },
            onSuccess: (response) => {
                let a = document.createElement('a');

                a.href =
                    'data:application/pdf;base64,' + response?.data?.nfFile;
                a.download = 'NFe_Conta_Ordem.pdf';
                a.click();
            },
        },
    );

    function getCompanyName(erpCode: string) {
        const company = companyOptions.find(
            (company: any) => company.value === erpCode,
        );

        return company?.label ?? '-';
    }

    function getModuleNames(invoiceItems: any) {
        let modules: string = '';
        let kits = '';
        let moduleArray: any[] = moduleOptions || [];

        moduleArray?.forEach((module: any) => {
            invoiceItems?.forEach((item: any) => {
                if (item.productErpCode === module.value) {
                    modules =
                        modules +
                        item.quantity.toString() +
                        ' ' +
                        module.label.toUpperCase() +
                        '-';
                }
            });
        });

        invoiceItems?.forEach((item: any) => {
            if (item.productErpCode[0] === '2') {
                kits =
                    kits +
                    item.quantity +
                    ' ' +
                    item.productName.toUpperCase() +
                    '-';
            }
        });

        return modules + kits;
    }

    const { user } = useAuth();

    const canEditEstimatedDeliveryDate = userHasRoles(user, [
        Role.Administrator,
        Role.Logistics,
    ]);

    const getDisplayedEstimatedDeliveryDate = (item: any) => {
        if (item?.invoiceProduction?.actualEstimatedDeliveryDate) {
            return formatDate(
                item.invoiceProduction.actualEstimatedDeliveryDate,
            );
        }

        return getDeliveryDate(item);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 140);

        return () => clearTimeout(timer);
    }, [refetch, filter, reset]);

    useEffect(() => {
        document.addEventListener('keydown', escFunction);

        return () => {
            document.removeEventListener('keydown', escFunction);
        };
    }, [escFunction]);

    return (
        <>
            <Row>
                <Col md={11}>
                    <Row className="mt-4 pl-2 pr-2">
                        <Col md={9}>
                            <StyledNav
                                variant="tabs"
                                activeKey={tab}
                                onSelect={(selectedKey: string) => {
                                    remove();

                                    setTab(selectedKey);

                                    const timer = setTimeout(() => {
                                        refetch();
                                    }, 140);

                                    return () => clearTimeout(timer);
                                }}
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="active">
                                        EM PRODUÇÃO
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="pending">
                                        PENDENTE
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey={
                                            Status.InvoicePayedErp +
                                            ',' +
                                            Status.InvoiceBilled +
                                            ',' +
                                            Status.InvoiceDispatched
                                        }
                                    >
                                        PAGOS
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey={
                                            Status.InvoiceApproved +
                                            ',' +
                                            Status.InvoiceAnalysis
                                        }
                                    >
                                        APROVADOS
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={'financing'}>
                                        FINANCIAMENTO
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={'triangulation'}>
                                        CONTA E ORDEM
                                    </Nav.Link>
                                </Nav.Item>
                            </StyledNav>
                        </Col>
                        <Col>
                            <StyledTdText>
                                {`Número de Pedidos: ${invoiceCount}`}
                            </StyledTdText>
                        </Col>
                        <Col>
                            <StyledTdText>
                                {`Total dos Pedidos: ${formatCurrency(
                                    invoicesTotalValue,
                                )}`}
                            </StyledTdText>
                        </Col>
                    </Row>

                    {tab === 'active' && (
                        <Row className="mt-4 pl-2 pr-2">
                            <Col md={12}>
                                <StyledNav
                                    variant="tabs"
                                    activeKey={month}
                                    onSelect={(selectedKey: number) => {
                                        remove();

                                        setMonth(selectedKey);

                                        const timer = setTimeout(() => {
                                            refetch();
                                        }, 140);

                                        return () => clearTimeout(timer);
                                    }}
                                >
                                    <Nav.Item>
                                        <Nav.Link eventKey={1}>
                                            JANEIRO
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={2}>
                                            FEVEREIRO
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={3}>MARÇO</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={4}>ABRIL</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={5}>MAIO</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={6}>JUNHO</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={7}>JULHO</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={8}>AGOSTO</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={9}>
                                            SETEMBRO
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={10}>
                                            OUTUBRO
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={11}>
                                            NOVEMBRO
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={12}>
                                            DEZEMBRO
                                        </Nav.Link>
                                    </Nav.Item>
                                </StyledNav>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>

            <Row className="mt-4 pl-2 pr-2" />

            <Row>
                <Col className="ml-2 mr-2">
                    <Table hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh>DATA PRODUÇÃO</StyledTh>
                                <StyledTh>Nº PEDIDO</StyledTh>
                                <StyledTh>COD. PROTHEUS PEDIDO</StyledTh>
                                <StyledTh>CLIENTE</StyledTh>
                                {tab === 'financing' && (
                                    <StyledTh>STATUS</StyledTh>
                                )}
                                <StyledTh>CIDADE</StyledTh>
                                <StyledTh>UF</StyledTh>
                                <StyledTh>TRANSPORTE</StyledTh>
                                <StyledTh>REDESPACHO</StyledTh>
                                {tab !==
                                    Status.InvoiceApproved +
                                        ',' +
                                        Status.InvoiceAnalysis && (
                                    <StyledTh>Nº NF</StyledTh>
                                )}
                                <StyledTh>VALOR</StyledTh>
                                {tab !== 'active' &&
                                    tab !== 'pending' &&
                                    tab !== 'financing' &&
                                    tab !== 'triangulation' && (
                                        <StyledTh>MODULOS</StyledTh>
                                    )}
                                {tab ===
                                    Status.InvoicePayedErp +
                                        ',' +
                                        Status.InvoiceBilled +
                                        ',' +
                                        Status.InvoiceDispatched && (
                                    <StyledTh>DATA DE PAGAMENTO</StyledTh>
                                )}
                                {tab === 'active' && (
                                    <StyledTh>DATA SAIDA DA FABRICA</StyledTh>
                                )}
                                {tab === 'active' && (
                                    <StyledTh>PREVISÃO DE ENTREGA</StyledTh>
                                )}
                                {tab === 'active' && (
                                    <StyledTh>DATA EFETIVA DE ENTREGA</StyledTh>
                                )}
                                <StyledTh>OBSERVAÇÃO</StyledTh>
                                <StyledTh></StyledTh>
                                <StyledTh></StyledTh>
                                <StyledTh></StyledTh>
                            </tr>
                        </thead>

                        <tbody>
                            {data?.pages &&
                                data?.pages.map((group: any) =>
                                    group?.data.map(
                                        (item: any, index2: any) => (
                                            <>
                                                <StyledTr
                                                    key={index2}
                                                    invoiceId={item.id}
                                                    selectedInvoiceId={
                                                        selectedInvoice?.id ?? 0
                                                    }
                                                >
                                                    <td>
                                                        {item.id !==
                                                            selectedInvoice?.id &&
                                                            formatDate(
                                                                item?.deliveryDate,
                                                            )}
                                                        {item.id ===
                                                            selectedInvoice?.id && (
                                                            <ReactDatePicker
                                                                className="form-control"
                                                                dateFormat="dd/MM/yyyy"
                                                                disabled={
                                                                    item.id !==
                                                                    selectedInvoice?.id
                                                                }
                                                                placeholderText="DD/MM/AAAA"
                                                                selected={
                                                                    selectedDeliveryDate
                                                                        ? DateTime.fromISO(
                                                                              selectedDeliveryDate,
                                                                          ).toJSDate()
                                                                        : undefined
                                                                }
                                                                onChange={(
                                                                    newDeliveryDate: any,
                                                                ) => {
                                                                    setSelectedDeliveryDate(
                                                                        DateTime.fromJSDate(
                                                                            newDeliveryDate,
                                                                        ).toISO(),
                                                                    );

                                                                    setHasChange(
                                                                        true,
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>{item?.id}</td>
                                                    <td>{item?.erpCode}</td>
                                                    <td>
                                                        {item?.customerName}
                                                    </td>
                                                    {tab === 'financing' && (
                                                        <td>
                                                            {getInvoiceStatusText(
                                                                item?.status,
                                                            )}
                                                        </td>
                                                    )}
                                                    <td>
                                                        {
                                                            item?.deliveryAddressErpCityName
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            item?.deliveryAddressErpState
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.id !==
                                                            selectedInvoice?.id &&
                                                            getCompanyName(
                                                                item?.logisticsCompanyId,
                                                            )}
                                                        {item.id ===
                                                            selectedInvoice?.id && (
                                                            <>
                                                                <Select
                                                                    options={
                                                                        companyOptions
                                                                    }
                                                                    value={companyOptions.find(
                                                                        (
                                                                            company: any,
                                                                        ) =>
                                                                            company.value ===
                                                                            selectedDeliveryCompany,
                                                                    )}
                                                                    styles={{
                                                                        control:
                                                                            tradeControl,
                                                                        option: optionsTheme,
                                                                    }}
                                                                    className="form-control-nexen"
                                                                    theme={
                                                                        theme
                                                                    }
                                                                    placeholder="NÃO ENCONTRADO"
                                                                    onChange={(
                                                                        newValue: any,
                                                                    ) => {
                                                                        setSelectedDeliveryCompany(
                                                                            newValue.value,
                                                                        );
                                                                        setHasChange(
                                                                            true,
                                                                        );
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item.id !==
                                                            selectedInvoice?.id &&
                                                            getCompanyName(
                                                                item?.reDispatchCompanyId,
                                                            )}
                                                        {item.id ===
                                                            selectedInvoice?.id && (
                                                            <>
                                                                <Select
                                                                    options={
                                                                        companyOptions
                                                                    }
                                                                    value={companyOptions.find(
                                                                        (
                                                                            company: any,
                                                                        ) =>
                                                                            company.value ===
                                                                            selectedReDispatchCompany,
                                                                    )}
                                                                    styles={{
                                                                        control:
                                                                            tradeControl,
                                                                        option: optionsTheme,
                                                                    }}
                                                                    className="form-control-nexen"
                                                                    theme={
                                                                        theme
                                                                    }
                                                                    placeholder="NÃO ENCONTRADO"
                                                                    onChange={(
                                                                        newValue: any,
                                                                    ) => {
                                                                        setSelectedReDispatchCompany(
                                                                            newValue.value,
                                                                        );
                                                                        setHasChange(
                                                                            true,
                                                                        );
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </td>
                                                    {tab !==
                                                        Status.InvoiceApproved +
                                                            ',' +
                                                            Status.InvoiceAnalysis && (
                                                        <td>{item?.nf}</td>
                                                    )}
                                                    <td>
                                                        {formatCurrency(
                                                            +item?.totalInvoice,
                                                        )}
                                                    </td>

                                                    {tab !== 'active' &&
                                                        tab !== 'pending' &&
                                                        tab !== 'financing' &&
                                                        tab !==
                                                            'triangulation' && (
                                                            <td>
                                                                {getModuleNames(
                                                                    item?.invoiceItems,
                                                                )}
                                                            </td>
                                                        )}
                                                    {tab ===
                                                        Status.InvoicePayedErp +
                                                            ',' +
                                                            Status.InvoiceBilled +
                                                            ',' +
                                                            Status.InvoiceDispatched && (
                                                        <td>
                                                            {formatDate(
                                                                item?.paidAt,
                                                            )}
                                                        </td>
                                                    )}
                                                    {tab === 'active' && (
                                                        <td>
                                                            {formatDate(
                                                                item
                                                                    ?.invoiceProduction
                                                                    ?.waybillDate,
                                                            ) || '-'}
                                                        </td>
                                                    )}
                                                    {tab === 'active' && (
                                                        <td>
                                                            {item.id ===
                                                                selectedInvoice?.id &&
                                                            canEditEstimatedDeliveryDate ? (
                                                                <ReactDatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/AAAA"
                                                                    selected={
                                                                        selectedEstimatedDeliveryDate
                                                                    }
                                                                    onChange={(
                                                                        newDate,
                                                                    ) => {
                                                                        setSelectedEstimatedDeliveryDate(
                                                                            newDate,
                                                                        );
                                                                        setHasChange(
                                                                            true,
                                                                        );
                                                                    }}
                                                                />
                                                            ) : (
                                                                getDisplayedEstimatedDeliveryDate(
                                                                    item,
                                                                )
                                                            )}
                                                        </td>
                                                    )}
                                                    {tab === 'active' && (
                                                        <td>
                                                            {item
                                                                ?.invoiceProduction
                                                                ?.actualDeliveryDate &&
                                                            item?.status ===
                                                                Status.InvoiceDelivered
                                                                ? formatDate(
                                                                      item
                                                                          ?.invoiceProduction
                                                                          ?.actualDeliveryDate,
                                                                  )
                                                                : '-'}
                                                        </td>
                                                    )}
                                                    {tab === 'pending' &&
                                                    item?.invoiceProduction
                                                        ?.observation ? (
                                                        <td>
                                                            <Button
                                                                variant="link"
                                                                onClick={() => {
                                                                    if (
                                                                        selectedInvoice?.id &&
                                                                        item.id !==
                                                                            selectedInvoice?.id
                                                                    ) {
                                                                        return;
                                                                    }
                                                                    setInvoiceObservation(
                                                                        {
                                                                            observation:
                                                                                item.id !==
                                                                                selectedInvoice?.id
                                                                                    ? item
                                                                                          ?.invoiceProduction
                                                                                          ?.observation
                                                                                    : invoiceObservation?.observation,
                                                                            invoiceId:
                                                                                item?.id,
                                                                        },
                                                                    );
                                                                    setShowObservationModal(
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    item
                                                                        ?.invoiceProduction
                                                                        ?.observation
                                                                }
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            <ReactFeedbackIcon
                                                                fill={
                                                                    item
                                                                        ?.invoiceProduction
                                                                        ?.observation &&
                                                                    item
                                                                        ?.invoiceProduction
                                                                        ?.observation !==
                                                                        ''
                                                                        ? '#f2994a'
                                                                        : '#808080'
                                                                }
                                                                width="25"
                                                                height="25"
                                                                className="ml-n3"
                                                                onClick={() => {
                                                                    if (
                                                                        selectedInvoice?.id &&
                                                                        item.id !==
                                                                            selectedInvoice?.id
                                                                    ) {
                                                                        return;
                                                                    }
                                                                    setInvoiceObservation(
                                                                        {
                                                                            observation:
                                                                                item.id !==
                                                                                selectedInvoice?.id
                                                                                    ? item
                                                                                          ?.invoiceProduction
                                                                                          ?.observation
                                                                                    : invoiceObservation?.observation,
                                                                            invoiceId:
                                                                                item?.id,
                                                                        },
                                                                    );
                                                                    setShowObservationModal(
                                                                        true,
                                                                    );
                                                                }}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </td>
                                                    )}
                                                    {item.isTriangulation ? (
                                                        <td>
                                                            <ReactTriangulationIcon
                                                                fill={
                                                                    item
                                                                        ?.invoiceTriangulation
                                                                        ?.status ===
                                                                    InvoiceTriangulationStatus.Approved
                                                                        ? '#6fcf97'
                                                                        : item
                                                                              ?.invoiceTriangulation
                                                                              ?.status
                                                                        ? '#febf00'
                                                                        : '#ff3243'
                                                                }
                                                                width="25"
                                                                height="25"
                                                                className="ml-n3 mr-2"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setInvoiceTriangulation(
                                                                        item.invoiceTriangulation,
                                                                    );
                                                                    setShowInvoiceTriangulationModal(
                                                                        true,
                                                                    );
                                                                }}
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td></td>
                                                    )}
                                                    {tab !==
                                                        Status.InvoiceApproved +
                                                            ',' +
                                                            Status.InvoiceAnalysis && (
                                                        <RolesGate
                                                            allowedRoles={[
                                                                Role.Logistics,
                                                                Role.Administrator,
                                                                Role.Production,
                                                            ]}
                                                        >
                                                            <td>
                                                                {}
                                                                <Dropdown key="left">
                                                                    <Dropdown.Toggle
                                                                        bsPrefix="nexen"
                                                                        as={
                                                                            Button
                                                                        }
                                                                        variant="text"
                                                                        onClick={async () => {
                                                                            if (
                                                                                item.id ===
                                                                                selectedInvoice?.id
                                                                            ) {
                                                                                const confirm: any =
                                                                                    await confirmDialog(
                                                                                        'Produção',
                                                                                        'Salvar dados do pedido?',
                                                                                        'info',
                                                                                    );

                                                                                if (
                                                                                    !confirm
                                                                                ) {
                                                                                    return;
                                                                                }

                                                                                saveInvoice(
                                                                                    item,
                                                                                    'active',
                                                                                );
                                                                                setSelectedInvoice(
                                                                                    {},
                                                                                );
                                                                                setInvoiceObservation(
                                                                                    {},
                                                                                );
                                                                                setSelectedDeliveryDate(
                                                                                    {},
                                                                                );
                                                                                setSelectedDeliveryCompany(
                                                                                    {},
                                                                                );
                                                                                setSelectedReDispatchCompany(
                                                                                    {},
                                                                                );
                                                                                setSelectedEstimatedDeliveryDate(
                                                                                    null,
                                                                                );
                                                                                setHasChange(
                                                                                    false,
                                                                                );

                                                                                return;
                                                                            }

                                                                            if (
                                                                                hasChange
                                                                            ) {
                                                                                const confirm: any =
                                                                                    await confirmDialog(
                                                                                        'Produção',
                                                                                        'Descartar alterações no pedido?',
                                                                                        'info',
                                                                                    );

                                                                                if (
                                                                                    !confirm
                                                                                ) {
                                                                                    return;
                                                                                }
                                                                            }

                                                                            setSelectedInvoice(
                                                                                item,
                                                                            );
                                                                            setInvoiceObservation(
                                                                                {
                                                                                    observation:
                                                                                        item
                                                                                            ?.invoiceProduction
                                                                                            ?.observation,
                                                                                    invoiceId:
                                                                                        item?.id,
                                                                                },
                                                                            );
                                                                            setSelectedDeliveryDate(
                                                                                item?.deliveryDate,
                                                                            );
                                                                            setSelectedDeliveryCompany(
                                                                                item?.logisticsCompanyId,
                                                                            );
                                                                            setSelectedReDispatchCompany(
                                                                                item?.reDispatchCompanyId,
                                                                            );

                                                                            if (
                                                                                item?.estimatedDeliveryDate
                                                                            ) {
                                                                                setSelectedEstimatedDeliveryDate(
                                                                                    new Date(
                                                                                        item.estimatedDeliveryDate,
                                                                                    ),
                                                                                );
                                                                            }
                                                                            setHasChange(
                                                                                false,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {item.id ===
                                                                            selectedInvoice?.id && (
                                                                            <Save
                                                                                width="20"
                                                                                height="30"
                                                                                fill="black"
                                                                                stroke="black"
                                                                            />
                                                                        )}
                                                                        {item.id !==
                                                                            selectedInvoice?.id && (
                                                                            <Settings
                                                                                width="20"
                                                                                height="30"
                                                                                fill="white"
                                                                                stroke="black"
                                                                            />
                                                                        )}
                                                                    </Dropdown.Toggle>
                                                                </Dropdown>
                                                            </td>
                                                        </RolesGate>
                                                    )}
                                                    {selectedInvoice?.id &&
                                                        item.id ===
                                                            selectedInvoice?.id && (
                                                            <>
                                                                <td />
                                                                <td>
                                                                    <ReactCancelIcon
                                                                        width="20"
                                                                        height="30"
                                                                        onClick={() => {
                                                                            setSelectedInvoice(
                                                                                {},
                                                                            );
                                                                            setInvoiceObservation(
                                                                                {},
                                                                            );
                                                                            setSelectedDeliveryDate(
                                                                                {},
                                                                            );
                                                                            setSelectedDeliveryCompany(
                                                                                {},
                                                                            );
                                                                            setSelectedReDispatchCompany(
                                                                                {},
                                                                            );
                                                                            setSelectedEstimatedDeliveryDate(
                                                                                null,
                                                                            );
                                                                            setHasChange(
                                                                                false,
                                                                            );
                                                                        }}
                                                                    >
                                                                        X
                                                                    </ReactCancelIcon>
                                                                </td>
                                                            </>
                                                        )}
                                                    {selectedInvoice?.id &&
                                                        item.id ===
                                                            selectedInvoice?.id && (
                                                            <>
                                                                <td />
                                                                <td>
                                                                    <ReactInvoiceAnalysis
                                                                        width="20"
                                                                        height="30"
                                                                        onClick={async () => {
                                                                            if (
                                                                                item.id ===
                                                                                selectedInvoice?.id
                                                                            ) {
                                                                                const confirm: any =
                                                                                    await confirmDialog(
                                                                                        'Pendente',
                                                                                        'Salvar dados do pedido?',
                                                                                        'info',
                                                                                    );

                                                                                if (
                                                                                    !confirm
                                                                                ) {
                                                                                    return;
                                                                                }

                                                                                saveInvoice(
                                                                                    item,
                                                                                    'pending',
                                                                                );
                                                                                setSelectedInvoice(
                                                                                    {},
                                                                                );
                                                                                setInvoiceObservation(
                                                                                    {},
                                                                                );
                                                                                setSelectedDeliveryDate(
                                                                                    {},
                                                                                );
                                                                                setSelectedDeliveryCompany(
                                                                                    {},
                                                                                );
                                                                                setSelectedReDispatchCompany(
                                                                                    {},
                                                                                );
                                                                                setSelectedEstimatedDeliveryDate(
                                                                                    null,
                                                                                );
                                                                                setHasChange(
                                                                                    false,
                                                                                );

                                                                                return;
                                                                            }
                                                                        }}
                                                                    >
                                                                        X
                                                                    </ReactInvoiceAnalysis>
                                                                </td>
                                                            </>
                                                        )}
                                                    <td>
                                                        <Dropdown key="left">
                                                            <Dropdown.Toggle
                                                                bsPrefix="nexen"
                                                                as={Button}
                                                                variant="text"
                                                            >
                                                                <ReactMoreIcon
                                                                    fill="#bdbdbd"
                                                                    width="10"
                                                                    height="20"
                                                                />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        mutationPrint.mutateAsync(
                                                                            item,
                                                                        )
                                                                    }
                                                                >
                                                                    <ReactPrintIcon
                                                                        fill="#707070"
                                                                        width="18"
                                                                        height="18"
                                                                        className="ml-n3 mr-2"
                                                                    />
                                                                    Baixar
                                                                    pedido
                                                                </Dropdown.Item>

                                                                {item.nfe && (
                                                                    <>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item
                                                                            onClick={async () => {
                                                                                if (
                                                                                    item.hasNFeSimpleShipment
                                                                                ) {
                                                                                    await mutationGetNfeSimpleShipment.mutateAsync(
                                                                                        item.id,
                                                                                    );

                                                                                    return;
                                                                                }

                                                                                window.location.href =
                                                                                    NFE_URL +
                                                                                    item.nfe;
                                                                            }}
                                                                        >
                                                                            <ReactTruckIcon
                                                                                fill="#707070"
                                                                                width="18"
                                                                                height="18"
                                                                                className="ml-n3 mr-2"
                                                                            />
                                                                            NFE
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )}

                                                                {item.nfeFutureSale && (
                                                                    <>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item
                                                                            onClick={async () => {
                                                                                if (
                                                                                    item.hasNFeFutureSale
                                                                                ) {
                                                                                    await mutationGetNfeFutureSale.mutateAsync(
                                                                                        item.id,
                                                                                    );

                                                                                    return;
                                                                                }

                                                                                window.location.href =
                                                                                    NFE_URL +
                                                                                    item.nfeFutureSale;
                                                                            }}
                                                                        >
                                                                            <ReactTruckIcon
                                                                                fill="#707070"
                                                                                width="18"
                                                                                height="18"
                                                                                className="ml-n3 mr-2"
                                                                            />
                                                                            NFE
                                                                            venda
                                                                            futura
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )}

                                                                {item
                                                                    .invoiceTriangulation
                                                                    ?.nf && (
                                                                    <>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item
                                                                            onClick={async () => {
                                                                                await mutationGetNfeTriangulation.mutateAsync(
                                                                                    item.id,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ReactTruckIcon
                                                                                fill="#707070"
                                                                                width="18"
                                                                                height="18"
                                                                                className="ml-n3 mr-2"
                                                                            />
                                                                            NFE
                                                                            conta
                                                                            e
                                                                            ordem
                                                                        </Dropdown.Item>
                                                                    </>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </StyledTr>
                                            </>
                                        ),
                                    ),
                                )}
                        </tbody>
                    </Table>
                </Col>

                <Col md={12} className="text-center" ref={loadMoreRef}>
                    {isFetchingNextPage || isRefetching ? (
                        <Col md={12} className="text-center">
                            <Spinner animation={'border'} />
                        </Col>
                    ) : (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>

            <ObservationModal
                show={showObservationModal}
                hasSelectedInvoice={typeof selectedInvoice?.id !== 'undefined'}
                onHide={() => {
                    setShowObservationModal(false);
                }}
                disabled={invoiceObservation?.invoiceId !== selectedInvoice?.id}
            />

            {showInvoiceTriangulationModal && (
                <InvoiceTriangulationModal
                    invoiceTriangulation={invoiceTriangulation}
                    show={showInvoiceTriangulationModal}
                    onHide={() => {
                        setShowInvoiceTriangulationModal(false);
                    }}
                />
            )}
        </>
    );
};

export { ProductionTable };
