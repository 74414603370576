/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
import { AUTH_API_URL } from '../../config/urls';
import useRequest from './request';

export function usePortalInvoiceHttpService() {
    const { patch } = useRequest({ baseURL: AUTH_API_URL });

    const URI = '/invoices';

    const patchInvoice = async (data: any) => {
        return await patch(`${URI}/updateFromLogistics`, data);
    };

    return {
        patchInvoice,
    };
}
