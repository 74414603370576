/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Accordion, Card, Image } from 'react-bootstrap';
import { FaSolarPanel } from 'react-icons/fa6';
import {
    PiBooks,
    PiCaretDown,
    PiCaretLeftBold,
    PiClipboard,
    PiCube,
    PiGear,
    PiHouse,
    PiPackage,
    PiQuestion,
    PiSignOut,
    PiTruck,
} from 'react-icons/pi';
import { useHistory } from 'react-router-dom';
import { ReactNode } from 'react';
import userIcon from '../../assets/images/icons/user.svg';
import { useAuth } from '../../hooks/use-auth';
import { DashboardRoutePath } from '../../routes/config';
import confirmDialog from '../../utils/dialog/confirmDialog';
import Responsable from '../Navigation/NavBar/Responsable';
import {
    StyledCardBody,
    StyledCardHeader,
    StyledContentDiv,
    StyledFillerDiv,
    StyledHeaderDiv,
    StyledListDiv,
    StyledMainDiv,
    StyledOptionDiv,
    StyledUserDiv,
    StyledUsernameDiv,
} from './styles';
import useAllowedPages from '../../hooks/useAllowedPages';
import useAllowedProjects from '../../hooks/useAllowedProjects';

interface SidebarProps {
    show: boolean;
    onHide: () => void;
}

export default function Sidebar({ show, onHide }: SidebarProps) {
    const { user, logout } = useAuth();

    const history = useHistory();

    const avatar = user.avatar
        ? Buffer.from(user.avatar, 'base64').toString('ascii')
        : '';

    const iconComponents: Record<string, ReactNode> = {
        PiHouse: <PiHouse />,
        PiPackage: <PiPackage />,
        PiTruck: <PiTruck />,
        PiClipboard: <PiClipboard />,
        FaSolarPanel: <FaSolarPanel />,
        PiGear: <PiGear />,
        PiBooks: <PiBooks />,
    };

    const getIcon = (iconName: string) => {
        return iconComponents[iconName] || <PiQuestion />;
    };

    const goToPath = (path: string) => {
        history.push(path);
        onHide();
    };

    if (!show) {
        return <Responsable hidden />;
    }

    const allowedProjects = useAllowedProjects();

    const allowedPages = useAllowedPages();

    return (
        <StyledMainDiv>
            <StyledContentDiv>
                <StyledHeaderDiv>
                    <span onClick={() => goToPath(DashboardRoutePath)}>
                        nexen
                    </span>
                    <PiCaretLeftBold onClick={onHide} />
                </StyledHeaderDiv>

                <StyledUserDiv>
                    <Image
                        className="avatar-border"
                        src={avatar || userIcon}
                        height="63px"
                        roundedCircle
                        style={{ borderColor: '#545454' }}
                    />
                    <StyledUsernameDiv>
                        <p>
                            <span>{user.name}</span>
                        </p>
                        <p>
                            <span>{user.email}</span>
                        </p>
                    </StyledUsernameDiv>
                </StyledUserDiv>

                <hr />

                <Responsable sidebar />

                <Accordion>
                    <Card style={{ border: 'none' }}>
                        <Accordion.Toggle
                            as={StyledCardHeader}
                            variant="link"
                            eventKey="0"
                        >
                            <PiCube size={24} />
                            <span>Módulos</span>
                            <PiCaretDown
                                size={24}
                                style={{ marginLeft: 'auto' }}
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <StyledCardBody>
                                {allowedProjects.map(
                                    ({ url, piIcon, name }) => (
                                        <a
                                            key={url}
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {getIcon(piIcon)}
                                            <span>{name}</span>
                                        </a>
                                    ),
                                )}
                            </StyledCardBody>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                <hr />

                <StyledListDiv>
                    {allowedPages.map(({ path, sidebarIcon, text }) => (
                        <StyledOptionDiv
                            key={path}
                            onClick={() => goToPath(path)}
                        >
                            {getIcon(sidebarIcon)}
                            <span>{text}</span>
                        </StyledOptionDiv>
                    ))}
                </StyledListDiv>

                <StyledOptionDiv
                    onClick={async () => {
                        const confirm: any = await confirmDialog(
                            'Deseja realmente sair?',
                        );

                        if (!confirm) {
                            return;
                        }

                        logout();
                    }}
                    style={{ marginTop: 'auto' }}
                >
                    <PiSignOut />
                    <span>Sair</span>
                </StyledOptionDiv>
            </StyledContentDiv>

            <StyledFillerDiv onClick={onHide} />
        </StyledMainDiv>
    );
}
