/* eslint-disable import/prefer-default-export */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';

import ReactDatePicker from 'react-datepicker';
import { useQuery } from 'react-query';
import {
    theme,
    tradeControl,
    optionsTheme,
} from '../../../../utils/theme/react-select-config';
import { useInvoice } from '../../../../contexts/invoiceContext';
import { useStatusHttpService } from '../../../../services/http/status-http';
import { useCompanyHttpService } from '../../../../services/http/company-http';
import { useInvoiceHttpService } from '../../../../services/http/invoice-http';

interface Props {
    toggleFilter: any;
}

const InvoiceFilter: React.FC<Props> = ({ toggleFilter }: any) => {
    const { control } = useInvoice();
    const { index } = useStatusHttpService();
    const { index: listCompanies } = useCompanyHttpService();
    const [statusOptions, setStatusOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [redispatchCompanyOptions, setRedispatchCompanyOptions] = useState(
        [],
    );

    const sellers = useQuery(['sellers'], loadSellers);

    const { getSellers } = useInvoiceHttpService();

    async function loadSellers() {
        try {
            const response = await getSellers();

            let sellers: any = [];

            if (response?.data) {
                response?.data.map((seller: any) =>
                    sellers.push({
                        label: seller.sellerName,
                        value: seller.sellerId,
                    }),
                );
            }

            return sellers;
        } catch (error) {
            console.log(error);
        }
    }

    async function loadStatuses(): Promise<void> {
        const response = await index();

        const options: any = [];

        response?.data?.data.map((item: any) =>
            options.push({ value: item?.id, label: item?.desc }),
        );

        setStatusOptions(options);
    }

    async function loadCompanies(): Promise<void> {
        const response = await listCompanies({});

        const options: any = [];
        const redispatchOptions: any = [];

        response?.data?.data.map((item: any) =>
            options.push({ value: item?.id, label: item?.name }),
        );

        setCompanyOptions(options);

        response?.data?.data.map((item: any) =>
            redispatchOptions.push({
                value: item?.protheusId,
                label: item?.name,
            }),
        );

        setRedispatchCompanyOptions(redispatchOptions);
    }

    useEffect(() => {
        loadStatuses();
        loadCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Row>
                    <Col>
                        {toggleFilter && (
                            <Card className="mt-4 mx-2">
                                <Card.Body>
                                    <Card.Title>
                                        <strong>Filtros</strong>
                                    </Card.Title>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>Status</Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="statuses"
                                                    options={statusOptions}
                                                    isMulti={true}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>
                                                    Transportadora
                                                </Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="companies"
                                                    options={companyOptions}
                                                    isMulti={true}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>
                                                    Transportadora de redespacho
                                                </Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="redispatchCompanies"
                                                    options={
                                                        redispatchCompanyOptions
                                                    }
                                                    isMulti={true}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group controlId="formBasicSeller">
                                                <Form.Label>
                                                    Integrador
                                                </Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="seller"
                                                    options={sellers.data}
                                                    isMulti={true}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                <Form.Group
                                                    controlId="formBasicStatus"
                                                    style={{ width: '100%' }}
                                                >
                                                    <Form.Label>
                                                        Previsão de entrega
                                                    </Form.Label>

                                                    <Controller
                                                        render={({
                                                            onChange,
                                                            value,
                                                        }) => (
                                                            <ReactDatePicker
                                                                className="form-control"
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="DD/MM/AAAA"
                                                                selected={value}
                                                                onChange={
                                                                    onChange
                                                                }
                                                            />
                                                        )}
                                                        control={control}
                                                        name="date"
                                                        defaultValue=""
                                                    />
                                                </Form.Group>

                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        alignSelf: 'center',
                                                        margin: '20px 10px 0px 10px',
                                                    }}
                                                >
                                                    à
                                                </span>

                                                <Form.Group
                                                    controlId="formBasicStatus"
                                                    style={{ width: '100%' }}
                                                >
                                                    <Controller
                                                        render={({
                                                            onChange,
                                                            value,
                                                        }) => (
                                                            <ReactDatePicker
                                                                className="form-control"
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="DD/MM/AAAA"
                                                                selected={value}
                                                                onChange={
                                                                    onChange
                                                                }
                                                            />
                                                        )}
                                                        control={control}
                                                        name="endDate"
                                                        defaultValue=""
                                                    />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col className="ml-2 mr-2">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <i className="fas fa-search"></i>
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <Controller
                                as={
                                    <Form.Control placeholder="Digite aqui o que procura..." />
                                }
                                type="text"
                                name="term"
                                control={control}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export { InvoiceFilter };
