import React from 'react';
import { Switch } from 'react-router-dom';
import List from '../../../pages/Production/List';
import PrivateRoute from '../../components/PrivateRoute';
import Update from '../../../pages/Production/Update';

export const ProductionRoutePath = '/produção';
export const ProductionUpdateRoutePath = '/produção/atualizar';

export const ProductionRoutesComponent: React.FC = () => (
    <Switch>
        <PrivateRoute path={ProductionRoutePath} component={List} exact />
        <PrivateRoute
            path={ProductionUpdateRoutePath}
            component={Update}
            exact
        />
    </Switch>
);
