import { Modal } from 'react-bootstrap';
import { LiaInfoCircleSolid } from 'react-icons/lia';
import {
    PiCheckCircle,
    PiQuestion,
    PiWarning,
    PiWarningOctagon,
} from 'react-icons/pi';
import { NotificationIcon } from '../../constants/notificationIcon';
import { StyledCancelButton, StyledConfirmButton, StyledModal } from './styles';

interface ConfirmationDialogProps {
    show: boolean;
    title: string;
    text: string;
    icon: NotificationIcon;
    onHide: () => void;
    onConfirm: () => void;
}

export default function ConfirmationDialog({
    show,
    title,
    text,
    icon,
    onHide,
    onConfirm,
}: ConfirmationDialogProps) {
    const bodyIcon = {
        [NotificationIcon.Info]: <LiaInfoCircleSolid />,
        [NotificationIcon.Warning]: <PiWarning />,
        [NotificationIcon.Danger]: <PiWarningOctagon />,
        [NotificationIcon.Success]: <PiCheckCircle />,
    }[icon] || <PiQuestion />;

    return (
        <StyledModal show={show} onHide={onHide} centered>
            <Modal.Body className="text-center">
                {bodyIcon}
                <Modal.Title>{title}</Modal.Title>
                <pre className="pre">{text}</pre>
                <StyledCancelButton onClick={onHide}>
                    Cancelar
                </StyledCancelButton>
                <StyledConfirmButton onClick={onConfirm}>
                    Confirmar
                </StyledConfirmButton>
            </Modal.Body>
        </StyledModal>
    );
}
