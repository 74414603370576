import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { registerLocale } from 'react-datepicker';
import br from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import { AuthProvider } from './hooks/use-auth';
import history from './services/history';
import { NotificationProvider } from './contexts/notificationContext';
import { InvoiceProvider } from './contexts/invoiceContext';
import { CompanyProvider } from './contexts/companyContext';
import { ProductionProvider } from './contexts/productionContext';
import { BranchProvider } from './contexts/branchContext';

function App() {
    const queryClient = new QueryClient();

    registerLocale('br', br);

    return (
        <>
            <Router history={history}>
                <QueryClientProvider client={queryClient}>
                    <ProductionProvider>
                        <InvoiceProvider>
                            <CompanyProvider>
                                <AuthProvider>
                                    <NotificationProvider>
                                        <BranchProvider>
                                            <Routes />
                                        </BranchProvider>
                                    </NotificationProvider>
                                </AuthProvider>
                                <ToastContainer />
                            </CompanyProvider>
                        </InvoiceProvider>
                    </ProductionProvider>
                </QueryClientProvider>
            </Router>
        </>
    );
}

export default App;
