import React, { useEffect } from 'react';
import { APP_URL } from '../../../config/urls';

const NotFound: React.FC = () => {
    useEffect(() => {
        window.location.replace(`${APP_URL}/dashboard` ?? '');
    }, []);

    return <h1>Not Found! Redirecting to home.</h1>;
};

export default NotFound;
