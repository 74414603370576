/* eslint-disable import/prefer-default-export */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import {
    theme,
    tradeControl,
    optionsTheme,
} from '../../../../utils/theme/react-select-config';
import { useProduction } from '../../../../contexts/productionContext';

interface Props {
    toggleFilter: any;
}

const ProductionFilter: React.FC<Props> = ({ toggleFilter }: any) => {
    const {
        states,
        cities,
        hasNfOptions,
        companyOptions,
        control,
        yearsOptions,
        reset,
        setStateParam,
        filterModuleOptions,
    } = useProduction();

    const clearCityFilter = () => {
        reset({
            city: null,
        });
    };

    return (
        <>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Row>
                    <Col>
                        {toggleFilter && (
                            <Card className="mt-4 mx-2">
                                <Card.Body>
                                    <Card.Title>
                                        <strong>Filtros</strong>
                                    </Card.Title>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>
                                                    Possui NF
                                                </Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="hasNf"
                                                    options={hasNfOptions}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>
                                                    Transportadora
                                                </Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="companies"
                                                    options={companyOptions}
                                                    isMulti={true}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>
                                                    Data de Produção
                                                </Form.Label>

                                                <Controller
                                                    render={({
                                                        onChange,
                                                        value,
                                                    }) => (
                                                        <ReactDatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/AAAA"
                                                            selected={value}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                    control={control}
                                                    name="date"
                                                    defaultValue=""
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>Estado</Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    render={({ value }) => (
                                                        <Select
                                                            options={states}
                                                            value={states.find(
                                                                (c: any) =>
                                                                    c.value ===
                                                                    value,
                                                            )}
                                                            onChange={(
                                                                state,
                                                            ) => {
                                                                setStateParam(
                                                                    state,
                                                                );
                                                                clearCityFilter();
                                                            }}
                                                            name="state"
                                                            styles={{
                                                                control:
                                                                    tradeControl,
                                                                option: optionsTheme,
                                                            }}
                                                            theme={theme}
                                                            placeholder="Selecione..."
                                                        />
                                                    )}
                                                    control={control}
                                                    name="state"
                                                    options={states}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>Cidade</Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="city"
                                                    options={cities}
                                                    isMulti={true}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>Módulos</Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="modules"
                                                    options={
                                                        filterModuleOptions
                                                    }
                                                    isMulti={true}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder="Selecione..."
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>Ano</Form.Label>

                                                <Controller
                                                    isClearable={false}
                                                    className="form-control-nexen"
                                                    as={Select}
                                                    control={control}
                                                    name="years"
                                                    options={yearsOptions}
                                                    styles={{
                                                        control: tradeControl,
                                                        option: optionsTheme,
                                                    }}
                                                    theme={theme}
                                                    placeholder={`${new Date().getFullYear()}`}
                                                    defaultValue={null}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group controlId="formBasicStatus">
                                                <Form.Label>
                                                    Observações
                                                </Form.Label>

                                                <Controller
                                                    className="form-control-nexen"
                                                    as={
                                                        <Form.Control placeholder="Digite aqui o que procura..." />
                                                    }
                                                    type="text"
                                                    control={control}
                                                    name="observation"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col className="ml-2 mr-2">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <i className="fas fa-search"></i>
                                </InputGroup.Text>
                            </InputGroup.Prepend>

                            <Controller
                                as={
                                    <Form.Control placeholder="Digite aqui o que procura..." />
                                }
                                type="text"
                                name="term"
                                control={control}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export { ProductionFilter };
