import styled from 'styled-components';

const StyledDatePicker = styled.div<{ isInvalid?: boolean }>`
    input {
        border-color: ${(props) =>
            props.isInvalid ? 'rgba(220, 53, 69, 0.5)' : ''};
    }
`;

export default StyledDatePicker;
