/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useCompanyHttpService } from '../services/http/company-http';
import usePortalHttpService from '../services/http/portal-http';

const ProductionContext = createContext<any>({});
const useProduction = () => useContext(ProductionContext);

interface filters {
    term: string;
    companies: [{ label: string; value: number }];
    hasNf: [{ label: string; value: number }];
    date: Date;
    state: { label: string; value: number };
    city: { label: string; value: number };
    years: [{ label: string; value: number }];
    modules: [{ label: string; value: number }];
}

export interface InvoiceTriangulationProps {
    id: number;
    invoiceId: number;
    customerDocument: string;
    customerName: string;
    addressCity: string;
    addressState: string;
    totalValue: number;
    additionalData: string;
    status: string;
    erpCode: string;
    nf: string;
    nfSeries: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

function ProductionProvider(props: any) {
    const [companyOptions, setCompanyOptions] = useState([]);
    const [moduleOptions, setModuleOptions] = useState([]);
    const [filterModuleOptions, setFilterModuleOptions] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState<any>();
    const [showObservationModal, setShowObservationModal] = useState(false);
    const [invoiceObservation, setInvoiceObservation] = useState({});
    const [invoiceTriangulation, setInvoiceTriangulation] =
        useState<InvoiceTriangulationProps | null>(null);
    const [showInvoiceTriangulationModal, setShowInvoiceTriangulationModal] =
        useState(false);
    const [hasNfOptions] = useState([
        { label: 'Nenhum', value: '99' },
        { label: 'Sim', value: '1' },
        { label: 'Não', value: '0' },
    ]);
    const [states, setStates] = useState<{ label: string; value: number }[]>(
        [],
    );
    const [cities, setCities] = useState<{ label: string; value: number }[]>(
        [],
    );
    const [stateParam, setStateParam] = useState<any>();
    const [yearsOptions] = useState([
        { label: '2021', value: '0' },
        { label: '2022', value: '1' },
        { label: '2023', value: '2' },
        { label: '2024', value: '3' },
        { label: '2025', value: '4' },
    ]);

    const { index: listCompanies } = useCompanyHttpService();
    const {
        indexModules,
        indexStates,
        indexCities,
        indexModulesFilterOptions,
    } = usePortalHttpService();

    const { control, watch, reset, formState } = useForm<filters>({
        shouldUnregister: false,
        defaultValues: {
            term: '',
            companies: [],
            hasNf: undefined,
            date: undefined,
            state: undefined,
            city: undefined,
            years: undefined,
            modules: undefined,
        },
    });

    const formData = watch();

    useEffect(() => {
        async function loadCompanies(): Promise<void> {
            const response = await listCompanies({});

            const options: any = [];

            response?.data?.data.map((item: any) =>
                options.push({ value: item?.protheusId, label: item?.name }),
            );

            setCompanyOptions(options);
        }

        async function loadModules(): Promise<void> {
            const response = await indexModules();

            const options: any = [];

            response?.data?.map((item: any) =>
                options.push({ value: item?.erpCode, label: item?.name }),
            );

            setModuleOptions(options);
        }

        const getStates = async () => {
            const response: any = await indexStates();

            setStates((prevStates) => [
                ...prevStates,
                { label: 'Nenhum', value: 0 },
            ]);

            response.data.forEach((item: any, index: number) => {
                setStates((prevStates) => [
                    ...prevStates,
                    { label: item.charge_address_erp_state, value: index + 1 },
                ]);
            });

            return response;
        };

        async function loadFilterModulesOptions(): Promise<void> {
            const response = await indexModulesFilterOptions();

            const options: any = [];

            response?.data?.map((item: any) =>
                options.push({
                    value: item?.product_erp_code,
                    label: item?.product_name,
                }),
            );

            setFilterModuleOptions(options);
        }

        loadModules();

        loadCompanies();

        getStates();

        loadFilterModulesOptions();
    }, []);

    useEffect(() => {
        const getCities = async () => {
            if (stateParam) {
                const response: any = await indexCities({
                    state: stateParam.label,
                });
                setCities([]);
                response.data.forEach((item: any, index: number) => {
                    setCities((prevCities) => [
                        ...prevCities,
                        {
                            label: item.charge_address_erp_city_name,
                            value: index,
                        },
                    ]);
                });
                return response;
            }
        };

        getCities();
    }, [stateParam]);

    return (
        <ProductionContext.Provider
            value={{
                control,
                watch,
                reset,
                formData,
                formState,
                states,
                cities,
                hasNfOptions,
                moduleOptions,
                companyOptions,
                showObservationModal,
                setShowObservationModal,
                invoiceObservation,
                setInvoiceObservation,
                invoiceTriangulation,
                setInvoiceTriangulation,
                showInvoiceTriangulationModal,
                setShowInvoiceTriangulationModal,
                selectedInvoice,
                setSelectedInvoice,
                yearsOptions,
                setStateParam,
                stateParam,
                filterModuleOptions,
            }}
            {...props}
        />
    );
}

export { ProductionProvider, useProduction };
