/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable indent */

import { Form, Col, Modal, Row, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useProduction } from '../../../../contexts/productionContext';
import usePortalHttpService from '../../../../services/http/portal-http';
import { StyledCloseButton } from './styles';

const ObservationModal = (props: any) => {
    const { invoiceObservation, setInvoiceObservation, selectedInvoice } =
        useProduction();

    const { patchInvoice } = usePortalHttpService();

    async function saveInvoice(): Promise<void> {
        let invoice = selectedInvoice;

        delete invoice.deliveryDate;

        if (invoice.invoiceProduction) {
            invoice.invoiceProduction.observation =
                invoiceObservation.observation;
        } else {
            invoice.invoiceProduction = {
                observation: invoiceObservation.observation,
            };
        }

        try {
            await patchInvoice(selectedInvoice);
            toast.success('Observação salva com sucesso!');
        } catch (error: any) {
            toast.error(error.message);
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExit={() => false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Observação do pedido
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group controlId="formBasicComment">
                            <Form.Label>Observação</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={invoiceObservation.observation}
                                disabled={props?.disabled}
                                onChange={(newValue) => {
                                    setInvoiceObservation({
                                        invoiceId: invoiceObservation.invoiceId,
                                        observation: newValue.target.value,
                                    });
                                }}
                                placeholder={'Digite aqui as observações...'}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {props?.hasSelectedInvoice && (
                    <Row>
                        <Col className="text-right">
                            <Button
                                className="float-right w-25"
                                onClick={() => {
                                    saveInvoice();
                                }}
                            >
                                Salvar Observação
                            </Button>
                        </Col>
                    </Row>
                )}
            </Modal.Body>

            <Modal.Footer>
                <StyledCloseButton onClick={() => props.onHide()}>
                    Fechar
                </StyledCloseButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ObservationModal;
