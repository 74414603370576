import React from 'react';
import { Switch } from 'react-router-dom';
import { List } from '../../../pages/Dashboard';
import PrivateRoute from '../../components/PrivateRoute';

export const DashboardRoutePath = '/dashboard';

export const DashboardRoutesComponent: React.FC = () => (
    <Switch>
        <PrivateRoute path={DashboardRoutePath} component={List} exact />
    </Switch>
);
