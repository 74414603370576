import { ReactComponent as InvoiceIcon } from '../assets/images/icons/invoice.svg';
import { ReactComponent as TruckIcon } from '../assets/images/icons/truck.svg';
import { InvoicesRoutePath, CompaniesRoutePath } from '../routes/config';
import { Role } from './role';

const cards = [
    {
        name: 'Pedidos',
        to: InvoicesRoutePath,
        icon: <InvoiceIcon />,
        text: 'Listagem dos pedidos e seus status de transporte.',
        allowedRoles: [],
    },
    {
        name: 'Transportadoras',
        to: CompaniesRoutePath,
        icon: <TruckIcon />,
        text: 'Listagem das Transportadoras parceiras.',
        allowedRoles: [
            Role.Administrator,
            Role.Commercial,
            Role.CommercialSupervisor,
            Role.CustomerSuccess,
            Role.Financial,
            Role.Integrator,
            Role.Logistics,
            Role.PointsAdministrator,
            Role.TechnicalSupport,
            Role.Production,
        ],
    },
];

export default cards;
